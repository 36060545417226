import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { isEmpty } from 'ramda';

// api
import CompanyService from '../../../../service/company-service';

// components
import { Button } from 'primereact/button';

const DownloadCompanyDelegation = ({ applicationId = 0 }) => {
    const [loading, setLoading] = useState(false);
    const [delega, setDelega] = useState({});

    const onClickDownload = () => {
        if (delega.filePath) {
            window.open(encodeURI(delega.filePath), '_blank').focus()
        }
    }

    const getDellegaCallback = (data) => {
        if (data.data) {
            setDelega(data.data);
        }
        setLoading(false);
    }

    const errDellegaCallback = () => {
        setDelega([]);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        CompanyService.getCompanyDelega(getDellegaCallback, errDellegaCallback, [
            ['applicationId', applicationId]
        ]);
    }, [])

    return (applicationId && applicationId !== 0
            ? <Button
                type="button"
                disabled={loading || isEmpty(delega)}
                onClick={onClickDownload}
                label={__('Scarica la delega', 'gepafin')}
                icon="pi pi-download"
                iconPos="right"/> : null
    )
}

export default DownloadCompanyDelegation;