import React, { useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { isEmpty, head } from 'ramda';

// store
import { storeGet, storeSet } from '../../../../store';
import { __ } from '@wordpress/i18n';

const NodeInitialForm = ({ data: { id, label = '' } }) => {
    const flowData = storeGet.main.flowData();
    const [value, setValue] = useState('');

    useEffect(() => {
        const flowForms = storeGet.main.flowForms();
        const form = head(flowForms.filter(o => String(o.id) === String(id)));
        const flowDataItem = head(flowData.filter(o => String(o.formId) === String(id)));

        if (form && flowDataItem) {
            const field = head(form.content.filter(o => o.id === flowDataItem.chosenField));

            if (field) {
                const label = head(field.settings.filter(o => o.name === 'label'));
                setValue(label ? label.value : field.label);
            }
        }
    }, [flowData]);

    return (
        <div className="nodeInitialForm">
            <label>
                {label}
            </label>
            <span>{value}</span>
            {/*{options && !isEmpty(options)
                ? <select onChange={onChangeFn} value={value}>
                    <option value="">{__('Scegli il campo', 'gepafin')}</option>
                    {options.map(o => <option key={o.name} value={o.name}>
                        {o.label}
                    </option>)}
                </select> : null}*/}
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable={true}
            />
        </div>
    );
}

export default NodeInitialForm;