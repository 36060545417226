import React from 'react';
import { Button } from 'primereact/button';
import { __ } from '@wordpress/i18n';
import { isNil } from 'ramda';
import ArchiveDocument from '../ArchiveDocument';

const ListOfFiles = ({ files, updateFn, shouldDisableFieldFn, name, ndg, applicationId }) => {

    return (
        <ol className="appPageSection__list">
            {files.map((o, i) => <li key={o.id} className="appPageSection__listItem">
                <div className="appPageSection__listItemRow">
                    <span>{o.label}</span>
                    <div className="appPageSection__iconActions">
                        {o.fileDetail && o.fileDetail.length === 1
                            ? <Button icon="pi pi-eye" rounded
                                      onClick={() => {
                                          window.open(o.fileDetail[0].filePath, '_blank').focus()
                                      }}
                                      outlined severity="info"
                                      aria-label={__('Mostra', 'gepafin')}/> : null}
                        <Button icon="pi pi-thumbs-up" rounded outlined
                                disabled={shouldDisableFieldFn(name)}
                                severity={!isNil(o.valid) && o.valid ? 'success' : 'secondary'}
                                onClick={() => updateFn(
                                    true,
                                    [name, i, 'valid']
                                )}
                                aria-label={__('Su', 'gepafin')}/>
                        <Button icon="pi pi-thumbs-down" rounded outlined
                                disabled={shouldDisableFieldFn(name)}
                                severity={!isNil(o.valid) && !o.valid ? 'danger' : 'secondary'}
                                onClick={() => updateFn(
                                    false,
                                    [name, i, 'valid']
                                )}
                                aria-label={__('Giu', 'gepafin')}/>
                    </div>
                </div>
                {o.fileDetail && o.fileDetail.length > 1
                    ? <ul style={{
                        width: '100%',
                        paddingLeft: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        {o.fileDetail.map((k) => <li key={k.id} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <span>{k.name}</span>
                            <div className="appPageSection__iconActions">
                                <ArchiveDocument ndg={ndg} applicationId={applicationId} fileId={k.id}/>
                                <Button icon="pi pi-eye" rounded
                                        onClick={() => {
                                            window.open(k.filePath, '_blank').focus()
                                        }}
                                        outlined severity="info"
                                        aria-label={__('Mostra', 'gepafin')}/>
                            </div>
                        </li>)}
                    </ul>
                    : null}
            </li>)}
        </ol>
    )
}

export default ListOfFiles;
