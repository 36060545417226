import React from 'react';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import { RadioButton } from 'primereact/radiobutton';

const Radio = ({
                   fieldName,
                   label,
                   control,
                   errors,
                   defaultValue,
                   config = {},
                   infoText = null,
                   options = [],
    disabled = false
               }) => {
    const input = <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={config}
        render={({ field, fieldState }) =>
            options.map(o => <div className="appForm__fieldItem" key={o.name}>
                <RadioButton
                    inputId={`${fieldName}_${o.name}`}
                    disabled={disabled}
                    name={fieldName}
                    value={o.name}
                    onChange={(e) => field.onChange(e.value)}
                    checked={field.value === o.name}
                    className={classNames({ 'p-invalid': fieldState.invalid })}/>
                <label htmlFor={`${fieldName}_${o.name}`}>{o.label}</label>
            </div>)}/>
    return (
        <>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required || config.isRequired ? <span className="appForm__field--required">*</span> : null}
            </label>
            {input}
            {infoText ? <small>{infoText}</small> : null}
        </>)
}

export default Radio;