const zustandXOpts = {
    devtools: {
        enabled: process.env.NODE_ENV !== 'production'
    },
    persist: {
        enabled: true,
        partialize: (state) => ({
            //userData: state.userData,
            token: state.token,
            chosenCompanyId: state.chosenCompanyId,
        }),
    }
}

export default zustandXOpts;