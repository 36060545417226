import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class LookupdataService {

    static getItems = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/lookUpData/type`, callback, errCallback, queryParams);
    };
}
