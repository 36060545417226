import React from 'react';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';

const TextArea = ({
                      fieldName,
                      label,
                      control,
                      rows = 4,
                      errors,
                      defaultValue,
                      config = {},
                      infoText = null,
                      disabled = false
                  }) => {
    return (
        <>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required || config.isRequired ? <span className="appForm__field--required">*</span> : null}
            </label>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={defaultValue}
                rules={config}
                render={({ field, fieldState }) => (
                    <InputTextarea id={field.name}
                                   disabled={disabled}
                                   rows={rows}
                                   {...field}
                                   className={classNames({ 'p-invalid': fieldState.invalid })}/>
                )}/>
            {infoText ? <small>{infoText}</small> : null}
        </>)
}

export default TextArea;