import React, { useCallback, useEffect } from 'react'
import { __ } from '@wordpress/i18n';
import { isEmpty } from 'ramda';

// store
import { storeSet, useStore } from '../../../../store';

// components
import BuilderElement from '../BuilderElement';
import BuilderElementItem from '../BuilderElementItem';
import { Sidebar } from 'primereact/sidebar';
import BuilderElementSettings from '../BuilderElementSettings';
import BuilderDropzone from '../BuilderDropzone';
import BlockingOverlay from '../../../../components/BlockingOverlay';

const FormBuilder = ({ bandoStatus }) => {
    const elements = useStore().main.formElements();
    const elementItems = useStore().main.elementItems();
    const activeElement = useStore().main.activeElement();
    const isAsyncRequest = useStore().main.isAsyncRequest();

    const renderField = useCallback((field, index) => {
        return (
            <BuilderElement
                key={field.id}
                index={index}
                id={field.id}
                label={field.label}
                name={field.name}
                bandoStatus={bandoStatus}
            />
        )
    }, [bandoStatus]);

    const renderItem = useCallback((item) => {
        return (
            <BuilderElementItem
                key={item.id}
                dbId={item.id}
                label={item.label}
                name={item.name}
                description={item.description}
            />
        )
    }, []);

    const closeSettings = () => {
        storeSet.main.activeElement('');
    }

    useEffect(() => {
        return () => {
            storeSet.main.activeElement('');
        }
    }, []);

    return (
        <>
            <Sidebar visible={!isEmpty(activeElement)} onHide={closeSettings} className="formBuilder__elementSettings">
                <h2>{__('Impostazioni del campo modulo', 'gepafin')}</h2>
                {!isEmpty(activeElement) ? <BuilderElementSettings closeSettingsFn={closeSettings} bandoStatus={bandoStatus}/> : null}
            </Sidebar>
            <div className="formBuilder">
                <div className="formBuilder__main">
                    <h2>{__('Trascina qui gli elementi del Form', 'gepafin')}*</h2>
                    <div className="formBuilder__content">
                        {!isEmpty(elements)
                            ? elements.map((field, i) => renderField(field, i))
                            : <BuilderDropzone/>}
                    </div>
                </div>
                <div className="formBuilder__aside">
                    <h2>{__('Elementi del Form', 'gepafin')}</h2>
                    <ul className="formBuilder__list">
                        {elementItems.map((item) => renderItem(item))}
                    </ul>
                </div>
                <BlockingOverlay shouldDisplay={isAsyncRequest}/>
            </div>
        </>
    )
}

export default FormBuilder;
