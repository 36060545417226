import React, { useEffect, useRef, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { is, isEmpty, isNil } from 'ramda';
import { wrap } from 'object-path-immutable';

// tools
import getDateFromISOstring from '../../../../helpers/getDateFromISOstring';
import set404FromErrorResponse from '../../../../helpers/set404FromErrorResponse';
import uniqid from '../../../../helpers/uniqid';

// store
import { storeGet } from '../../../../store';

// api
import CommunicationService from '../../../../service/communication-service';

// components
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

const SoccorsoComunications = ({ amendmentId, soccorsoStatus }) => {
    const [comms, setComms] = useState([]);
    const [isVisibleNewCommDialog, setIsVisibleNewCommDialog] = useState(false);
    const [newCommData, setNewCommData] = useState({});
    const [isLoadingCommunication, setIsLoadingCommunication] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        if (amendmentId && amendmentId !== 0) {
            setIsLoadingCommunication(true);
            CommunicationService.getCommsByAmendmentId(amendmentId, getCommsCallback, errGetCommsCallback);
        }
    }, [amendmentId]);

    const getCommsCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setComms(data.data.commentsList.map(o => getFormattedCommsData(o)));
        }
        setIsLoadingCommunication(false);
    }

    const errGetCommsCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        setIsLoadingCommunication(false);
    }
    
    const getFormattedCommsData = (data) => {
        data.id = isNil(data.id) ? uniqid('id') : data.id;
        data.commentedDate = is(String, data.commentedDate) ? new Date(data.commentedDate) : (data.commentedDate ? data.commentedDate : '');
        data.createdDate = is(String, data.createdDate) ? new Date(data.createdDate) : (data.createdDate ? data.createdDate : '');
        data.updatedDate = is(String, data.updatedDate) ? new Date(data.updatedDate) : (data.updatedDate ? data.updatedDate : '');
        return data;
    };

    const headerNewComDialog = () => {
        return <span>{__('Aggiungi comunicazione', 'gepafin')}</span>
    }

    const hideNewComDialog = () => {
        setIsVisibleNewCommDialog(false);
        setNewCommData({
            title: '',
            comment: ''
        });
    }

    const footerNewComDialog = () => {
        return <div>
            <Button type="button" label={__('Anulla', 'gepafin')} onClick={hideNewComDialog} outlined/>
            <Button
                type="button"
                disabled={isLoadingCommunication || isEmpty(newCommData.title) || isEmpty(newCommData.comment)}
                label={__('Invia', 'gepafin')} onClick={createCommunication}/>
        </div>
    }

    const openNewCommDialog = () => {
        setIsVisibleNewCommDialog(true);
        setNewCommData({
            title: '',
            comment: ''
        });
    }

    const updateNewCommData = (value, path) => {
        const newData = wrap(newCommData).set(path.split('.'), value).value();
        setNewCommData(newData);
    }

    const createCommunication = () => {
        setIsLoadingCommunication(true);
        CommunicationService.createCommunication(amendmentId, newCommData, createCommunicationCallback, errCreateCommunicationCallback);
    }

    const createCommunicationCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
            setComms([...comms, getFormattedCommsData(data.data)])
            setIsVisibleNewCommDialog(false);
        }
        setIsLoadingCommunication(false);
    }

    const errCreateCommunicationCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        setIsLoadingCommunication(false);
    }

    const displayCommIcon = (comm) => {
        const userData = storeGet.main.userData();
        return userData.id === comm.senderUserId
            ? <i className="pi pi-upload"></i>
            : <i className="pi pi-download"></i>;
    }

    const getCommRowClass = (comm) => {
        const userData = storeGet.main.userData();
        return userData.id === comm.senderUserId ? 'outgoing' : 'incoming';
    }

    return (
        <>
        <Toast ref={toast}/>
            <table className="myTable">
                <thead className="myThead">
                <tr>
                    <th style={{ width: 50 }}></th>
                    <th style={{ width: 250 }}>{__('Data', 'gepafin')}</th>
                    <th style={{ width: '100%' }}>{__('Comunicazione', 'gepafin')}</th>
                </tr>
                </thead>
                <tbody className="myTbody">
                {!isNil(comms) && !isEmpty(comms)
                    ? comms.map((o, i) => <tr key={o.id} className={getCommRowClass(o)}>
                        <td valign="top">
                            {displayCommIcon(o)}
                        </td>
                        <td valign="top">
                            {getDateFromISOstring(o.commentedDate)}
                        </td>
                        <td>
                            <h3>{o.title}</h3>
                            <p>{o.comment}</p>
                        </td>
                    </tr>)
                    : <tr>
                        <td></td>
                        <td>-</td>
                        <td>-</td>
                    </tr>}
                </tbody>
            </table>

            <Button
                style={{ marginTop: 30 }}
                onClick={openNewCommDialog}
                disabled={soccorsoStatus === 'CLOSE'}
                type="button"
                label={__('Aggiungi Comunicazione', 'gepafin')}
                icon="pi pi-plus" iconPos="right"/>

            <Dialog
                visible={isVisibleNewCommDialog}
                modal
                header={headerNewComDialog}
                footer={footerNewComDialog}
                style={{ maxWidth: '600px', width: '100%' }}
                onHide={hideNewComDialog}>
                <div className="appForm__field">
                    <label
                        className={classNames({ 'p-error': isEmpty(newCommData.title) })}>
                        {__('Titolo', 'gepafin')}*
                    </label>
                    <InputText value={newCommData.title}
                               disabled={soccorsoStatus === 'CLOSE'}
                               invalid={isEmpty(newCommData.title)}
                               onChange={(e) => updateNewCommData(e.target.value, 'title')}/>

                    <label
                        className={classNames({ 'p-error': isEmpty(newCommData.comment) })}>
                        {__('Contenuto', 'gepafin')}*
                    </label>
                    <InputTextarea
                        value={newCommData.comment}
                        disabled={soccorsoStatus === 'CLOSE'}
                        rows={5} cols={30}
                        invalid={isEmpty(newCommData.comment)}
                        onChange={(e) => updateNewCommData(e.target.value, 'comment')}/>
                </div>
            </Dialog>
        </>
    )
}

export default SoccorsoComunications;