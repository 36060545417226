import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class AmendmentsService {

    static getSoccorsoByApplEvalId = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/amendments/applicationEvaluation/${id}`, callback, errCallback, queryParams);
    };

    static getSoccorsoByApplId = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/amendments/application/${id}`, callback, errCallback, queryParams);
    };

    static getSoccorsoById = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/amendments`, callback, errCallback, queryParams);
    };

    static getSoccorsoByPreInstructorId = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/amendments/user/${id}`, callback, errCallback, queryParams);
    };

    static createSoccorso = (body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/amendments`, body, callback, errCallback, queryParams);
    };

    static updateSoccorso = (id, body, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/amendments/${id}`, body, callback, errCallback, queryParams);
    };

    static extendSoccorso = (id, days, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/amendments/${id}/extendExpiration`, {}, callback, errCallback, [
            ['extendedDays', days]
        ]);
    };

    static sendReminderForSoccorso = (id, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/amendments/${id}/reminder`, {}, callback, errCallback, queryParams);
    };

    static closeSoccorso = (id, body, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/amendments`, body, callback, errCallback, [
            ['id', id]
        ]);
    };
}
