import { useState, useEffect } from 'react'
import { head, pathOr } from 'ramda';

// store
import { useStore } from '../../../../store';
import renderHtmlContent from '../../../../helpers/renderHtmlContent';

const BuilderElementProperLabel = ({ id, defaultLabel }) => {
    const elements = useStore().main.formElements();
    const element = head(elements.filter(o => o.id === id));
    const [label, setLabel] = useState('');
    const isRequired = pathOr(false, ['validators', 'isRequired'], element)

    useEffect(() => {
        const label = head(element.settings.filter(o => o.name === 'label'));
        const text = head(element.settings.filter(o => o.name === 'text'));

        if (label) {
            setLabel(label.value);
        } else if (text) {
            setLabel(text.value);
        } else {
            setLabel(defaultLabel);
        }
    }, [elements]);

    return <div className="label">
        {renderHtmlContent(label)}
        {isRequired ? <span className="appForm__field--required">*</span> : null}
    </div>
}

export default BuilderElementProperLabel;