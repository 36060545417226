import React from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { wrap } from 'object-path-immutable';

const RenderTable = ({ data, columns, setRowsFn }) => {
    const table = useReactTable({
        data,
        columns,
        defaultColumn: {
            cell: ({ getValue, row: { index }, column: { id }, table }) => {
                const initialValue = getValue();

                const onBlur = (e) => {
                    table.options.meta?.updateData(index, id, e.target.value);
                };

                return (
                    <input
                        value={initialValue ? initialValue : ''}
                        onChange={(e) => table.options.meta?.updateData(index, id, e.target.value)}
                        onBlur={onBlur}
                    />
                );
            },
        },
        getCoreRowModel: getCoreRowModel(),
        meta: {
            updateData: (rowIndex, columnId, value) => {
                const newRowsData = wrap(data).set([rowIndex, columnId], value).value();
                setRowsFn(newRowsData);
            },
        }
    });

    return (
        <table>
            <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        return (
                            <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                    <>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </>
                                )}
                            </th>
                        );
                    })}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map((row) => {
                return (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                            return (
                                <td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
            </tbody>
        </table>
    )
}

export default RenderTable