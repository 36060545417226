import { mapValuesKey } from 'zustand-x';

// stores
import { mainStore } from './main';

// Global store - initial data
const dashboardStore = {
    main:       mainStore
};

// Global hook selectors
export const useStore = () => mapValuesKey('use', dashboardStore);
// Global tracked hook selectors
export const useTrackedStore = () => mapValuesKey('useTracked', dashboardStore);
// Global getter selectors
export const storeGet = mapValuesKey('get', dashboardStore);
// Global actions
export const storeSet = mapValuesKey('set', dashboardStore);

export default dashboardStore;