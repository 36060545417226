import React, { useState, useEffect, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { is, isEmpty, isNil } from 'ramda';
import "quill/dist/quill.core.css";

// store
import { storeSet, useStore } from '../../store';

// tools
import getNumberWithCurrency from '../../helpers/getNumberWithCurrency';
import getDateFromISOstring from '../../helpers/getDateFromISOstring';

// components
import { Skeleton } from 'primereact/skeleton';
import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import BandoService from '../../service/bando-service';
import { Messages } from 'primereact/messages';
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import renderHtmlContent from '../../helpers/renderHtmlContent';

const BandoView = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [newQuestion, setNewQuestion] = useState('');
    const bandoMsgs = useRef(null);

    const closePreview = () => {
        navigate(`/bandi/${id}`);
    }

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setData(getFormattedBandiData(data.data));
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallback = (data) => {
        if (bandoMsgs.current && data.message) {
            bandoMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormattedBandiData = (data) => {
        data.dates = data.dates.map(v => is(String, v) ? new Date(v) : (v ? v : ''));
        return data;
    };

    useEffect(() => {
        const parsed = parseInt(id)
        const bandoId = !isNaN(parsed) ? parsed : 0;

        BandoService.getBando(bandoId, getCallback, errGetCallback);
    }, [id]);

    return (
        <div className="appPage">
            {!isAsyncRequest && !isEmpty(data)
                ? <div className="appPage__pageHeader">
                    <h1>{data.name}</h1>
                    <p>
                        {__('Data:', 'gepafin')}
                        <span>{getDateFromISOstring(data.createdDate)}</span>
                    </p>
                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                </>}

            <div className="appPage__spacer"></div>
            <Messages ref={bandoMsgs}/>

            {!isAsyncRequest && !isEmpty(data)
                ? <div className="appPage__content">
                    <div className="appPageSection__preview">
                        <Button
                            type="button"
                            outlined
                            onClick={closePreview}
                            label={__('Chiudi Anteprima', 'gepafin')}
                            icon="pi pi-arrow-left" iconPos="left"/>
                    </div>

                    {!isEmpty(data.images)
                        ? <picture className="appPageSection__hero">
                            <source srcSet={data.images[0] ? data.images[0].filePath : ''}/>
                            <img src={data.images[0] ? data.images[0].filePath : ''} alt={data.name}/>
                        </picture> : null}

                    <div className="appPageSection__withBorder">
                        <h2>{__('Descrizione breve', 'gepafin')}</h2>
                        <div className="ql-editor">
                            {renderHtmlContent(data.descriptionShort)}
                        </div>
                    </div>

                    <div className="appPageSection__row">
                        <div className="appPageSection__withBorder">
                            <p className="appPageSection__pMeta">
                                <span>{__('Importo totale', 'gepafin')}</span>
                                <span>{getNumberWithCurrency(data.amount)}</span>
                            </p>
                            <p className="appPageSection__pMeta">
                                <span>{__('Importo minimo per progetto', 'gepafin')}</span>
                                <span>{getNumberWithCurrency(data.amountMin)}</span>
                            </p>
                            <p className="appPageSection__pMeta">
                                <span>{__('Importo massimo per progetto', 'gepafin')}</span>
                                <span>{getNumberWithCurrency(data.amountMax)}</span>
                            </p>
                        </div>

                        <div className="appPageSection__withBorder">
                            <p className="appPageSection__pMeta">
                                <span>{__('Data apertura', 'gepafin')}</span>
                                <span>{getDateFromISOstring(data.dates[0])} {data.startTime}</span>
                            </p>
                            <p className="appPageSection__pMeta">
                                <span>{__('Data chiusura', 'gepafin')}</span>
                                <span>{getDateFromISOstring(data.dates[1])} {data.endTime}</span>
                            </p>
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Descrizione dettagliata', 'gepafin')}</h2>
                        <div className="ql-editor">
                            {renderHtmlContent(data.descriptionLong)}
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Requisiti di Partecipazione', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <ul>
                                {data.aimedTo.map((o, i) => <li key={i}>
                                    {o.value}
                                </li>)}
                            </ul>
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Documentazione Richiesta', 'gepafin')}</h2>
                        <div className="ql-editor">
                            {renderHtmlContent(data.documentationRequested)}
                        </div>
                    </div>

                    {/*<div className="appPageSection__withBorder">
                        <h2>{__('Criteri di Valutazione', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <ul>
                                {data.criteria.map((o, i) => <li key={i}>
                                    {o.value} {o.score > 0 ? sprintf(__(' (%d punti)'), o.score) : null}
                                </li>)}
                            </ul>
                        </div>
                    </div>*/}

                    <div className="appPageSection__withBorder">
                        <h2>{__('Allegati', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <ul>
                                {data.docs
                                    .filter(o => o.source === 'CALL' && o.type === 'DOCUMENT')
                                    .map((o, i) => <li key={i}>
                                    <a href={o.filePath} target="_blank" rel="noreferrer">{o.name}</a>
                                </li>)}
                            </ul>
                        </div>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('FAQ', 'gepafin')}</h2>
                        <Accordion>
                            {data.faq
                                .filter(o => o.isVisible)
                                .map((o, i) => <AccordionTab key={i} header={renderHtmlContent(o.value)}>
                                <div className="ql-editor">
                                    {renderHtmlContent(o.response)}
                                </div>
                            </AccordionTab>)}
                        </Accordion>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('Non hai trovato la risposta che cercavi?', 'gepafin')}</h2>
                        <div className="appForm__field">
                            <label htmlFor="newQuestion">{__('Fai una domanda', 'gepafin')}</label>
                            <InputTextarea
                                id="newQuestion"
                                disabled
                                rows={7}
                                value={newQuestion}
                                placeholder={__('Digita qui la tua domanda', 'gepafin')}
                                onChange={(e) => setNewQuestion(e.target.value)}
                                aria-describedby="newQuestion-help"/>
                            <small id="newQuestion-help">
                                {__('Riceverai una notifica quando ti risponderemo', 'gepafin')}
                            </small>
                        </div>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('Download Documenti', 'gepafin')}</h2>
                        <div className="appPageSection__actions">
                            <Button
                                type="button"
                                disabled={true}
                                outlined
                                onClick={() => {}}
                                label={__('Scarica Bando Completo', 'gepafin')}
                                icon="pi pi-download" iconPos="right"/>
                            <Button
                                type="button"
                                disabled={true}
                                outlined
                                onClick={() => {}}
                                label={__('Scarica Modulistica', 'gepafin')}
                                icon="pi pi-download" iconPos="right"/>
                            <Button
                                type="button"
                                disabled={true}
                                onClick={() => {}}
                                label={__('Presenta Domanda', 'gepafin')}
                                icon="pi pi-save" iconPos="right"/>
                            {/*<Button
                                type="button"
                                outlined
                                rounded
                                disabled={true}
                                onClick={() => {}}
                                label={__('Aggiungi a preferiti', 'gepafin')}
                                icon="pi pi-heart" iconPos="left"/>*/}
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Contatti per Assistenza', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <p>Email: {data.email}</p>
                            {!isNil(data.phoneNumber) ? <p>{__('Telefono', 'gepafin')}: +39 {data.phoneNumber}</p> : null}
                        </div>
                    </div>

                    <div className="appPageSection__preview">
                        <Button
                            type="button"
                            outlined
                            onClick={closePreview}
                            label={__('Chiudi Anteprima', 'gepafin')}
                            icon="pi pi-arrow-left" iconPos="left"/>
                    </div>
                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem"></Skeleton>
                </>}
        </div>
    )

}

export default BandoView;