import React from 'react';

// tools
import getBandoLabel from '../../helpers/getBandoLabel';
import getBandoSeverity from '../../helpers/getBandoSeverity';

// components
import { Tag } from 'primereact/tag';

const ProperBandoLabel = ({ status }) => {
    return(
        <Tag className="bandoStatusTag" value={getBandoLabel(status)} severity={getBandoSeverity(status)} />
    )
}

export default ProperBandoLabel;