import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../ItemTypes';

// store
import { storeSet } from '../../../../store';

const BuilderDropzone = () => {
    const dropzoneRef = useRef();

    const [, drop] = useDrop({
        accept: ItemTypes.FIELD,
        drop(item, monitor) {
            return item;
        },
        hover(item, monitor) {
            storeSet.main.moveElement(-1, 0, item);
            item.index = 0;
        }
    });

    drop(dropzoneRef);

    return (
        <div ref={dropzoneRef} className="dropzone"></div>
    )
}

export default BuilderDropzone;