import React, { useEffect, useState } from 'react';
import { __ } from '@wordpress/i18n';

// components
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

// tools
import uniqid from '../../../../../../helpers/uniqid';

const ElementSettingRepeater = ({
                                    value,
                                    name,
                                    setDataFn,
                                    bandoStatus
                                }) => {
    const [stateFieldData, setStateFieldData] = useState([]);

    const removeItem = (index) => {
        const newData = stateFieldData.toSpliced(index, 1);
        setStateFieldData(newData);
    }

    const addNewItem = () => {
        setStateFieldData([...stateFieldData, { name: uniqid('o'), label: '' }]);
    }

    const onInputChange = (e, index) => {
        const { value } = e.target;
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                o.label = value;
            }
            return o;
        })
        setStateFieldData(newData);
    }

    const properField = (item, i) => {
        return <InputText value={item.label} onInput={(e) => onInputChange(e, i)}/>
    }

    useEffect(() => {
        const storeFieldData = value ?? [];
        setStateFieldData(storeFieldData);
    }, []);

    useEffect(() => {
        setDataFn(name, [...stateFieldData]);
    }, [stateFieldData])

    return (
        <div className="formElementSettings__repeater">
            {stateFieldData.map((o, i) => <div key={i} className="formElementSettings__repeaterItem">
                <div className="p-inputgroup flex-1">
                    {properField(o, i)}
                    <Button icon="pi pi-times" disabled={bandoStatus === 'PUBLISH'} className="p-button-danger" onClick={() => removeItem(i)}/>
                </div>
            </div>)}
            <Button type="button" disabled={bandoStatus === 'PUBLISH'} outlined label={__('Aggiungi', 'gepafin')} onClick={addNewItem}/>
        </div>
    )
}

export default ElementSettingRepeater;