import React from 'react';
import { range } from 'ramda';
import { __ } from '@wordpress/i18n';

// components
import { Steps } from 'primereact/steps';

const ApplicationSteps = ({ totalSteps = 0, activeStepIndex }) => {
    const rangeArr = range(1, totalSteps + 1);
    const items = rangeArr.map(() => ({ label: __('Passo', 'gepafin') }));

    return(
        0 !== totalSteps
            ? <Steps model={items} activeIndex={activeStepIndex} readOnly/>
            : null
    )
}

export default ApplicationSteps