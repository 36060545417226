import validate from 'validate.js';
import { match, isEmpty, is, pathOr, isNil } from 'ramda';
import CodiceFiscale from 'codice-fiscale-js';

export const isPIVA = (v) => {
    const regexp = new RegExp(/^[0-9]{11}$/);
    //console.log('isPIVA', !isEmpty(match(regexp, String(v))))
    return !isEmpty(match(regexp, String(v)));
}

// example: DSDDSD99P23B352G
export const isCodiceFiscale = (v) => {
    //const regexp = new RegExp(/^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/, 'i')
    //return !isEmpty(match(regexp, v));
    //console.log('isCodiceFiscale', CodiceFiscale.check(v))
    return CodiceFiscale.check(v);
}

export const isCAP = (v) => {
    const regexp = new RegExp(/^[0-9]{5}$/);
    return !isEmpty(match(regexp, String(v)));
}

export const isIBAN = (v) => {
    const regexp = new RegExp(/^((NO)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(NO)[0-9A-Z]{13}|(BE)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(BE)[0-9A-Z]{14}|(DK|FO|FI|GL|NL)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(DK|FO|FI|GL|NL)[0-9A-Z]{16}|(MK|SI)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(MK|SI)[0-9A-Z]{17}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{18}|(HR|LI|LV|CH)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{1}|(HR|LI|LV|CH)[0-9A-Z]{19}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{20}|(GI|IL)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(GI|IL)[0-9A-Z]{21}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{22}|(PT)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{1}|(PT)[0-9A-Z]{23}|(IS|TR)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(IS|TR)[0-9A-Z]{24}|(FR|GR|IT|MC|SM)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(FR|GR|IT|MC|SM)[0-9A-Z]{25}|(AL|CY|HU|LB|PL)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(AL|CY|HU|LB|PL)[0-9A-Z]{26}|(MU)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(MU)[0-9A-Z]{28}|(MT)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(MT)[0-9A-Z]{29})$/)
    return !isEmpty(match(regexp, v));
}

export const isEmail = (v) => {
    const constraints = {
        from: {
            email: true
        }
    };
    const res = validate({from: v}, constraints);
    return !res;
}

export const isEmailPEC = (v) => {
    const constraints = {
        from: {
            email: true
        }
    };
    const res = validate({from: v}, constraints);
    return !res;
}

export const isUrl = (v) => {
    const constraints = {
        from: {
            url: true
        }
    };
    const res = validate({from: v}, constraints);
    return !res;
}

export const isMarcaDaBollo = (v) => {
    const regexp = new RegExp(/^[0-9]{14}$/);
    return !isEmpty(match(regexp, String(v)));
}

export const minChecks = (v, num) => {
    return is(Array, v) ? v.length >= parseInt(num) : false;
}

export const maxChecks = (v, num) => {
    return is(Array, v) ? v.length <= parseInt(num) : false;
}

export const nonEmptyTables = (v = [], tableCfg = []) => {
    const colsCfg = pathOr([], ['stateFieldData'], tableCfg);
    const nonPredefinedCells = colsCfg
        .filter(o => !o.predefined)
        .map(o => o.name);
    let isTableValid = true;
    let atLeastOneCellFilled = false

    if (is(Array, v)) {
        // eslint-disable-next-line array-callback-return
        v.map((row) => {
            if (isEmpty(row)) {
                isTableValid = false;
            } else {
                // eslint-disable-next-line array-callback-return
                nonPredefinedCells.map((k) => {
                    if (isNil(row[k]) || isEmpty(row[k])) {
                        isTableValid = atLeastOneCellFilled;
                    } else {
                        atLeastOneCellFilled = true;
                        isTableValid = true;
                    }
                });
            }
        });
    }

    return is(Array, v) ? v.length >= 1 && isTableValid : false;
}