import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class ApplicationEvaluationService {

    static getEvaluationByApplId = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/applicationEvaluation/application`, callback, errCallback, queryParams);
    };

    static updateEvaluation = (assignedApplicationId, body, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/applicationEvaluation/${assignedApplicationId}`, body, callback, errCallback, queryParams);
    };
}
