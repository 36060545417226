import React, { useState, useEffect, useRef, useMemo } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { is, isEmpty } from 'ramda';
import { wrap } from 'object-path-immutable';
import { klona } from 'klona';
import { useForm } from 'react-hook-form';

// store
import { storeSet, useStore } from '../../store';

// api
import AmendmentsService from '../../service/amendments-service';

// tools
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import getBandoLabel from '../../helpers/getBandoLabel';
import getDateFromISOstring from '../../helpers/getDateFromISOstring';
import renderHtmlContent from '../../helpers/renderHtmlContent';

// components
import { Button } from 'primereact/button';
import BlockingOverlay from '../../components/BlockingOverlay';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import FormField from '../../components/FormField';
import { Editor } from 'primereact/editor';
import { InputNumber } from 'primereact/inputnumber';
import SoccorsoComunications from './components/SoccorsoComunications';


const SoccorsoEditPreInstructor = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const { id, amendmentId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [isVisibleExtendTimeDialog, setIsVisibleExtendTimeDialog] = useState(false);
    const [extendedTime, setExtendedTime] = useState(3);
    const [isLoadingExtendingTime, setIsLoadingExtendingTime] = useState(false);
    const [isLoadingReminding, setIsLoadingReminding] = useState(false);
    const toast = useRef(null);
    const [formInitialData, setFormInitialData] = useState({});
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        register,
        trigger,
        getValues
    } = useForm({
        defaultValues: useMemo(() => {
            return formInitialData;
        }, [formInitialData]), mode: 'onChange'
    });

    const goToEvaluationPage = () => {
        navigate(`/domande/${id}`);
    }

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setData(getFormattedData(data.data));
            const formDataInitial = data.data.applicationFormFields.reduce((acc, cur) => {
                if (cur.fieldValue) {
                    acc[cur.fieldId] = cur.fieldValue;
                }
                return acc;
            }, {});
            setFormInitialData(formDataInitial);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormattedData = (data) => {
        data.startDate = is(String, data.startDate) ? new Date(data.startDate) : (data.startDate ? data.startDate : '');
        data.expirationDate = is(String, data.expirationDate) ? new Date(data.expirationDate) : (data.expirationDate ? data.expirationDate : '');
        return data;
    };

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-header" value="2"></button>
                <button className="ql-header" value="3"></button>
                <button className="ql-blockquote"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
        );
    };

    const header = renderHeader();

    const updateNewAmendmentData = (value, path) => {
        const newData = wrap(data).set(path.split('.'), value).value();
        setData(newData);
    }

    const onSubmit = () => {
    };

    const doUpdateAmendment = () => {
        trigger();
        let formValues = klona(getValues());
        const newFormValues = Object.keys(formValues)
            .reduce((acc, cur) => {
                let fieldVal = formValues[cur];

                fieldVal = isEmpty(fieldVal) ? null : fieldVal;
                fieldVal = is(Array, fieldVal) ? fieldVal.map(o => o.id).join(',') : null;

                acc.push({
                    'fieldId': cur,
                    'fieldValue': fieldVal
                });
                return acc;
            }, []);

        const submitData = {
            applicationFormFields: newFormValues
        }
        storeSet.main.setAsyncRequest();
        AmendmentsService.updateSoccorso(amendmentId, submitData, updateAmendmentCallback, errUpdateAmendmentCallback);
    }

    const updateAmendmentCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
            const newFormDataInitial = data.data.applicationFormFields.reduce((acc, cur) => {
                if (cur.fieldValue) {
                    acc[cur.fieldId] = cur.fieldValue;
                }
                return acc;
            }, formInitialData);
            setFormInitialData(newFormDataInitial);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errUpdateAmendmentCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const doCloseAmendment = () => {
        const submitData = {
            internalNote: data.internalNote
        }
        storeSet.main.setAsyncRequest();
        AmendmentsService.closeSoccorso(amendmentId, submitData, closeAmendmentCallback, errCloseAmendmentCallback);
    }

    const closeAmendmentCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
            if (data.data.status) {
                updateNewAmendmentData(data.data.status, 'status')
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errCloseAmendmentCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const headerExtendRespDialog = () => {
        return <span>{__('Estendi scadenza', 'gepafin')}</span>
    }

    const hideExtendRespDialog = () => {
        setIsVisibleExtendTimeDialog(false);
    }

    const footerExtendRespDialog = () => {
        return <div>
            <Button type="button" label={__('Anulla', 'gepafin')} onClick={hideExtendRespDialog} outlined/>
            <Button
                type="button"
                disabled={isLoadingExtendingTime || isEmpty(extendedTime)}
                label={__('Invia', 'gepafin')} onClick={doExtendTimeResponse}/>
        </div>
    }

    const openExtendResponseTimeDialog = () => {
        setIsVisibleExtendTimeDialog(true);
        setExtendedTime(3);
    }

    const doExtendTimeResponse = () => {
        setIsLoadingExtendingTime(true);
        AmendmentsService.extendSoccorso(amendmentId, extendedTime, extendCallback, errExtendCallback);
    }

    const extendCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
            setIsVisibleExtendTimeDialog(false);
        }
        setIsLoadingExtendingTime(false);
    }

    const errExtendCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        setIsLoadingExtendingTime(false);
    }

    const sendReminder = () => {
        setIsLoadingReminding(true);
        AmendmentsService.sendReminderForSoccorso(amendmentId, reminderCallback, errReminderCallback)
    }

    const reminderCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
        }
        setIsLoadingReminding(false);
    }

    const errReminderCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        setIsLoadingReminding(false);
    }

    useEffect(() => {
        if (formInitialData) {
            //reset();
            Object.keys(formInitialData).map(k => setValue(k, formInitialData[k]));
            trigger();
        }
    }, [formInitialData]);

    useEffect(() => {
        const parsedSoccorsoId = parseInt(amendmentId);
        const soccorsoEntityId = !isNaN(parsedSoccorsoId) ? parsedSoccorsoId : 0;

        AmendmentsService.getSoccorsoById(getCallback, errGetCallback, [['id', soccorsoEntityId]]);
    }, [amendmentId]);

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Soccorso Istruttorio - Dettagli', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>
            <Toast ref={toast}/>
            <BlockingOverlay shouldDisplay={isAsyncRequest}/>

            <div className="appPageSection__row">
                <Button
                    type="button"
                    outlined
                    onClick={goToEvaluationPage}
                    label={__('Indietro', 'gepafin')}
                    icon="pi pi-arrow-left" iconPos="left"/>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPage__content">
                <div className="appPageSection__withBorder columns">
                    <p className="appPageSection__pMeta">
                        <span>{__('ID domanda', 'gepafin')}</span>
                        <span>{data.applicationId}</span>
                    </p>
                    <p className="appPageSection__pMeta">
                        <span>{__('Bando', 'gepafin')}</span>
                        <span>{data.callName}</span>
                    </p>
                    <p className="appPageSection__pMeta">
                        <span>{__('Beneficiario', 'gepafin')}</span>
                        <span>{data.beneficiaryName}</span>
                    </p>
                    <p className="appPageSection__pMeta">
                        <span>{__('Inizio', 'gepafin')}</span>
                        <span>{getDateFromISOstring(data.startDate)}</span>
                    </p>
                    <p className="appPageSection__pMeta">
                        <span>{__('Scadenza', 'gepafin')}</span>
                        <span>{getDateFromISOstring(data.expirationDate)}</span>
                    </p>
                    <p className="appPageSection__pMeta">
                        <span>{__('Stato', 'gepafin')}</span>
                        <span>{getBandoLabel(data.status)}</span>
                    </p>
                </div>

                <div className="appPageSection">
                    <h2>{__('Dettagli Richiesta', 'gepafin')}</h2>
                    <div className="appPageSection columns">
                        <div>
                            <h3>{__('Documenti Richiesti', 'gepafin')}</h3>
                            <ol className="appPageSection__list">
                                {data.formFields
                                    ? data.formFields.map((o, i) => <li key={o.fieldId}
                                                                        style={{ flexDirection: 'row' }}>
                                        <span>{o.label}</span>
                                    </li>) : null}
                            </ol>
                        </div>
                        <div>
                            <h3>{__('Note e spiegazioni', 'gepafin')}</h3>
                            <div className="appPageSection__withBorder grey ql-editor"
                                 style={{ minHeight: '200px' }}>
                                {renderHtmlContent(data.note)}
                            </div>
                        </div>

                    </div>
                </div>

                <div className="appPageSection">
                    <h2>{__('Comunicazioni', 'gepafin')}</h2>
                    <SoccorsoComunications amendmentId={amendmentId} soccorsoStatus={data.status}/>
                </div>

                <div className="appPageSection">
                    <h2>{__('Documenti Ricevuti', 'gepafin')}</h2>

                    <form className="appForm" onSubmit={handleSubmit(onSubmit)}>
                        {data.formFields
                            ? data.formFields.map((o, i) => {
                                return <FormField
                                    key={o.fieldId}
                                    disabled={data.status === 'CLOSE'}
                                    type="fileupload"
                                    setDataFn={setValue}
                                    saveFormCallback={doUpdateAmendment}
                                    fieldName={o.fieldId}
                                    label={o.label}
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    defaultValue={formInitialData[o.fieldId] ? formInitialData[o.fieldId] : []}
                                    accept={[]}
                                    source="AMENDMENT"
                                    sourceId={data.applicationId}
                                    multiple={true}
                                />
                            }) : null}
                    </form>
                </div>

                <div className="appForm__field">
                    <label>{__('Motivazioni / Note Interne', 'gepafin')}</label>
                    <div style={{ position: 'relative' }}>
                        <BlockingOverlay shouldDisplay={data.status === 'CLOSE'}/>
                        <Editor
                            value={data.internalNote}
                            readOnly={data.status === 'CLOSE'}
                            placeholder={__('Digita qui il messagio', 'gepafin')}
                            headerTemplate={header}
                            onTextChange={(e) => updateNewAmendmentData(
                                e.htmlValue,
                                'internalNote'
                            )}
                            style={{ height: 80 * 3, width: '100%' }}
                        />
                    </div>
                </div>

                <div className="appPage__spacer"></div>

                <div className="appPageSection__hr">
                    <span>{__('Azioni', 'gepafin')}</span>
                </div>

                <div className="appPageSection">
                    <div className="appPageSection__actions">
                        <Button
                            type="button"
                            onClick={sendReminder}
                            disabled={isLoadingReminding || data.status === 'CLOSE'}
                            outlined
                            label={__('Invia Sollecito', 'gepafin')}
                            icon="pi pi-send"
                        />
                        <Button
                            type="button"
                            onClick={openExtendResponseTimeDialog}
                            disabled={isLoadingExtendingTime || data.status === 'CLOSE'}
                            outlined
                            label={__('Estendi Scadenza', 'gepafin')}
                            icon="pi pi-stopwatch"
                        />
                        {/*<Button
                            type="button"
                            onClick={doUpdateAmendment}
                            disabled={isAsyncRequest || data.status === 'CLOSE'}
                            label={__('Salva bozza', 'gepafin')}
                            icon="pi pi-save" iconPos="right"/>*/}
                        <Button
                            type="button"
                            onClick={doCloseAmendment}
                            disabled={isAsyncRequest || data.status === 'CLOSE'}
                            label={__('Chiudi Soccorso Istruttorio', 'gepafin')}
                            icon="pi pi-times" iconPos="right"/>
                    </div>
                </div>

            </div>

            <Dialog
                visible={isVisibleExtendTimeDialog}
                modal
                header={headerExtendRespDialog}
                footer={footerExtendRespDialog}
                style={{ maxWidth: '600px', width: '100%' }}
                onHide={hideExtendRespDialog}>
                <div className="appForm__field">
                    <label
                        className={classNames({ 'p-error': isEmpty(extendedTime) })}>
                        {__('Giorni', 'gepafin')}*
                    </label>
                    <InputNumber
                        keyfilter="int"
                        disabled={data.status === 'CLOSE'}
                        value={extendedTime}
                        showButtons
                        onChange={(e) => setExtendedTime(e.value)}/>
                </div>
            </Dialog>
        </div>
    )

}

export default SoccorsoEditPreInstructor;