import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { createI18n, setLocaleData } from '@wordpress/i18n';
import { I18nProvider } from '@wordpress/react-i18n';
import './assets/scss/theme.scss';
import { isEmpty, head } from 'ramda'

// store
import { useStore, storeSet, storeGet } from './store';

// api
import AuthenticationService from './service/authentication-service';

const i18n = createI18n({}, 'gepafin');

function App() {
    const role = useStore().main.getRole();
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const isRedirectedOnceNoCompany = useStore().main.isRedirectedOnceNoCompany();

    const callback = (data) => {
        if (data.status === 'SUCCESS') {
            storeSet.main.userData(data.data);
        } else {
            storeSet.main.doLogout();
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
    }

    useEffect(() => {
        if (role === 'ROLE_BENEFICIARY') {
            const userData = storeGet.main.userData();
            if (userData.companies && !isEmpty(userData.companies)) {
                storeSet.main.companies(userData.companies);
                const company = head(userData.companies.filter(o => o.id === chosenCompanyId));

                if (!company) {
                    storeSet.main.chosenCompanyId(userData.companies[0].id);
                }
            } else {
                storeSet.main.chosenCompanyId(0);
                const { origin, href } = window.location;
                const url = `${origin}/agguingi-azienda`;

                if (!isRedirectedOnceNoCompany && url !== href) {
                    storeSet.main.isRedirectedOnceNoCompany(true);
                    window.location.replace('/agguingi-azienda')
                }
            }
        }
    }, [role]);

    useEffect(() => {
        storeSet.main.setAsyncRequest();
        AuthenticationService.me(callback, errCallback);

        fetch('/languages/en_US.json')
            .then((res) => res.json())
            .then(res => {
                setLocaleData(res.locale_data['gepafin'], 'gepafin')
            })
    }, []);

    return (
        <I18nProvider i18n={i18n}>
            <BrowserRouter>
                <Routes role={role} chosenCompanyId={chosenCompanyId}/>
            </BrowserRouter>
        </I18nProvider>
    );
}

export default App;
