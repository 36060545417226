import { createStore } from 'zustand-x';

import zustandXOpts from './zustand-x-opts';
import initialStore from './initial';
import selectors from './selectors';
import { actionsAlpha, actionsBeta } from './actions';

export const mainStore = createStore('main')(initialStore, zustandXOpts)
    .extendSelectors(selectors)
    .extendActions(actionsAlpha)
    .extendActions(actionsBeta);