import React, { useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { head, isEmpty } from 'ramda';
import { __ } from '@wordpress/i18n';

import { useStore, storeSet, storeGet } from '../../../../store';

const NodeIntermediateForm = ({ data: { id, label = '' } }) => {
    const flowEdges = useStore().main.flowEdges();
    const flowData = useStore().main.flowData();
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');

    const onChangeFn = (e) => {
        const { value } = e.target;
        const data = {
            formId: String(id),
            chosenField: '',
            chosenValue: value
        }
        setValue(value);
        storeSet.main.addFlowData(data);
    }

    useEffect(() => {
        const edge = head(flowEdges.filter(o => o.target === String(id)));
        if (edge) {
            const sourceForm = edge.source;
            const sourceFormData = head(flowData.filter(o => String(o.formId) === sourceForm));
            const flowForms = storeGet.main.flowForms();
            const form = head(flowForms.filter(o => String(o.id) === String(sourceForm)));

            if (form && sourceFormData) {
                const { chosenField } = sourceFormData;
                const field = head(form.content.filter(o => o.id === chosenField));
                if (field) {
                    const options = head(field.settings.filter(o => o.name === 'options'));
                    if (options) {
                        setOptions(options.value);
                    }
                }
            }
        }

        const flowDataForm = head(flowData.filter(o => String(o.formId) === String(id)));

        if (flowDataForm) {
            setValue(flowDataForm.chosenValue);
        }
    }, [flowEdges, flowData]);

    return (
        <div className="nodeIntermediateForm">
            <Handle
                type="target"
                position={Position.Top}
                isConnectable={true}
            />
            <label>
                {label}
            </label>
            {options && !isEmpty(options)
                ? <select onChange={onChangeFn} value={value}>
                    <option value="">{__('Scegli il valore', 'gepafin')}</option>
                    {options.map(o => <option key={o.name} value={o.name}>
                        {o.label}
                    </option>)}
                </select> : null}
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable={true}
            />
        </div>
    );
}

export default NodeIntermediateForm;