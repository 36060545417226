const selectors = (state, get, api) => ({
    getToken: () => {
        return get.token();
    },
    getRole: () => {
        const userData = get.userData();
        return userData.role ? userData.role.roleType : '';
    },
    getPermissions: () => {
        const userData = get.userData();
        return userData.role ? userData.role.permissions : [];
    },
})

export default selectors;
