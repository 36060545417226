import React, { useState } from 'react';
import { __ } from '@wordpress/i18n';

// api
import ApplicationService from '../../../../service/application-service';

// components
import { Button } from 'primereact/button';

const DownloadSignedApplication = ({ applicationId = 0 }) => {
    const [loading, setLoading] = useState(false);
    const [, setDocument] = useState({});

    const onClickDownload = () => {
        setLoading(true);
        ApplicationService.getApplicationSignedPdf(applicationId, getSignedPdfCallback, errSignedPdfCallbacks);
    }

    const getSignedPdfCallback = (data) => {
        if (data.data) {
            setDocument([data.data]);
            if (data.data.filePath) {
                window.open(encodeURI(data.data.filePath), '_blank').focus()
            }
        }
        setLoading(false);
    }

    const errSignedPdfCallbacks = () => {
        setDocument([]);
        setLoading(false);
    }

    return (applicationId && applicationId !== 0
            ? <Button
                type="button"
                disabled={loading}
                onClick={onClickDownload}
                label={__('Scarica PDF firmato', 'gepafin')}
                icon="pi pi-download"
                iconPos="right"/> : null
    )
}

export default DownloadSignedApplication;