import React from 'react';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import { is, isEmpty } from 'ramda';

import { InputNumber } from 'primereact/inputnumber';

const NumberInput = ({
                         fieldName,
                         label,
                         control,
                         errors,
                         defaultValue = 0,
                         config = {},
                         infoText = null,
                         inputgroup = false,
                         icon = null,
                         locale = 'it-IT',
                         minFractionDigits = 0,
                         maxFractionDigits = 1,
                         min,
                         max,
                         disabled = false,
                         useGrouping = true
                     }) => {
    const minAttr = config.min ? config.min : min;
    const maxAttr = config.max ? config.max : max;
    const input = <Controller
        name={fieldName}
        control={control}
        defaultValue={is(Number, defaultValue) ? defaultValue : 0}
        rules={config}
        render={({ field, fieldState }) => (
            <InputNumber inputId={field.name}
                         disabled={disabled}
                         value={field.value}
                         onValueChange={(e) => field.onChange(e.value)}
                         min={minAttr}
                         max={maxAttr}
                         locale={locale}
                         useGrouping={useGrouping}
                         maxFractionDigits={!isNaN(parseInt(maxFractionDigits)) ? parseInt(maxFractionDigits) : 0}
                         minFractionDigits={!isNaN(parseInt(minFractionDigits)) ? parseInt(minFractionDigits) : 0}
                         className={classNames({ 'p-invalid': fieldState.invalid })}/>
        )}/>
    return (
        <>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required || config.isRequired ? <span className="appForm__field--required">*</span> : null}
                {minAttr && !isEmpty(minAttr) ? <span>(min. {minAttr})</span> : null}
                {maxAttr && !isEmpty(maxAttr) ? <span>(max. {maxAttr})</span> : null}
            </label>
            {inputgroup
                ? <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        {icon}
                    </span>
                    {input}
                </div>
                : input}
            {infoText ? <small>{infoText}</small> : null}
        </>)
}

export default NumberInput;