import React from 'react';
import { __ } from '@wordpress/i18n';
import { is } from 'ramda';

// components
import ElementSettingRepeater from '../ElementSettingRepeater';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Editor } from 'primereact/editor';

import { mimeTypes } from '../../../../../../configData';
import ElementSettingTableColumns from '../ElementSettingTableColumns';

const ElementSetting = ({ setting, changeFn, updateDataFn, bandoStatus }) => {

    const settingLabels = {
        label: __('Label', 'gepafin'),
        placeholder: __('Segnaposto', 'gepafin'),
        step: __('Numero Decimali', 'gepafin'),
        options: __('Opzioni', 'gepafin'),
        mime: __('Tipo di file', 'gepafin'),
        text: __('Testo formattato', 'gepafin'),
        table_columns: __('Colonne', 'gepafin'),
    }

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-header" value="2"></button>
                <button className="ql-header" value="3"></button>
                <button className="ql-blockquote"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
        );
    };

    const header = renderHeader();

    const getProperField = (setting) => {
        if (setting.name === 'options') {
            return <ElementSettingRepeater
                value={is(Array, setting.value) ? setting.value : []}
                name={setting.name}
                bandoStatus={bandoStatus}
                setDataFn={updateDataFn}/>
        } else if (setting.name === 'mime') {
            return <MultiSelect
                value={is(Array, setting.value) ? setting.value : []}
                onChange={(e) => updateDataFn(setting.name, e.value)}
                options={mimeTypes}
                optionLabel="name"
                display="chip"
                placeholder={__('Scegli', 'gepafin')} />
        } else if (setting.name === 'text') {
            return <Editor
                value={setting.value}
                headerTemplate={header}
                onTextChange={(e) => changeFn(e.htmlValue, setting.name)}
                style={{ height: 80 * 4 }}
            />
        } else if (setting.name === 'step') {
            return <InputText id={setting.name}
                              keyfilter="int"
                              aria-describedby={`${setting.name}-help`}
                              placeholder="0"
                              value={setting.value}
                              onChange={(e) => changeFn(e.target.value, setting.name)}/>
        } else if (setting.name === 'table_columns') {
            return <ElementSettingTableColumns
                value={is(Object, setting.value) ? setting.value : {}}
                name={setting.name}
                bandoStatus={bandoStatus}
                setDataFn={updateDataFn}/>
        } else {
            return <InputText id={setting.name} aria-describedby={`${setting.name}-help`}
                              value={setting.value}
                              onChange={(e) => changeFn(e.target.value, setting.name)}/>
        }
    }

    return <div className="formElementSettings__field" key={setting.name}>
        <label htmlFor={setting.name}>{settingLabels[setting.name]}</label>
        {getProperField(setting)}
    </div>
}

export default ElementSetting;