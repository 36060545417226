import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class FlowService {

    static getFlow = (id, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/flow/call/${id}`, callback, errCallback);
    };

    static createFlow = (id, body, callback, errCallback) => {
        NetworkService.put(`${API_BASE_URL}/flow/call/${id}`, body, callback, errCallback);
    };
}
