import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { uniq } from 'ramda';
import copy from 'copy-to-clipboard';

// store
import { useStore, storeSet } from '../../../../store';

// tools
import getBandoSeverity from '../../../../helpers/getBandoSeverity';
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getDateFromISOstring from '../../../../helpers/getDateFromISOstring';

// api
import UserService from '../../../../service/user-service';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import translationStrings from '../../../../translationStringsForComponents';

const AllUsersTable = () => {
    const users = useStore().main.users();
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            UserService.getUsers(getCallback, errGetCallbacks);
        }
    }, []);

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            storeSet.main.users(getFormattedData(data.data));
            setStatuses(uniq(data.data.map(o => o.status)))
            initFilters();
        }
        setLoading(false);
    }

    const errGetCallbacks = () => {
        setLoading(false);
    }

    const getFormattedData = (data) => {
        return data
            .filter(o => ['ROLE_SUPER_ADMIN', 'ROLE_PRE_INSTRUCTOR'].includes(o.role.roleType));
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            email: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            lastLogin: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            }
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined
                        onClick={clearFilter}/>
            </div>
        );
    };

    const dateLastAccessBodyTemplate = (rowData) => {
        return getDateFromISOstring(rowData.lastLogin);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999"/>;
    };

    const nameBodyTemplate = (rowData) => {
        return `${rowData.firstName} ${rowData.lastName}`;
    };

    const roleEmailTemplate = (rowData) => {
        return <div className="appPageSection__tableActions lessGap">
            <span className="truncatedText">{rowData.email}</span>
            <button className="iconBtn" onClick={() => copy(rowData.email)}>
                <i className="pi pi-copy"></i>
            </button>
        </div>;
    };

    const roleBodyTemplate = (rowData) => {
        return rowData.role.roleName;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         itemTemplate={statusItemTemplate} placeholder={__('Scegli uno', 'gepafin')}
                         className="p-column-filter"
                         showClear/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    /*const actionsBodyTemplate = (rowData) => {
        /!*return <Link to={`/utenti/${rowData.id}`}>
            <Button severity="info" label={__('Modifica', 'gepafin')} icon="pi pi-pencil" size="small" iconPos="right"/>
        </Link>*!/
        return null;
    }*/

    const header = renderHeader();

    return (
        <div className="appPageSection__table">
            <DataTable value={users} paginator showGridlines rows={10} loading={loading} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       emptyMessage={translationStrings.emptyMessage}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column body={nameBodyTemplate}
                        header={__('Nome utente', 'gepafin')}
                        filterPlaceholder={__('Cerca per nome', 'gepafin')}
                        style={{ minWidth: '12rem' }}/>
                <Column body={roleEmailTemplate} header={__('Email', 'gepafin')}
                        filter sortable
                        field="email"
                        filterPlaceholder={__('Cerca per email', 'gepafin')}
                        style={{ minWidth: '12rem' }}/>
                <Column body={roleBodyTemplate} header={__('Ruolo', 'gepafin')}
                        style={{ minWidth: '12rem' }}/>
                <Column field="status" header={__('Stato', 'gepafin')}
                        filterMenuStyle={{ width: '14rem' }}
                        style={{ width: '120px' }} body={statusBodyTemplate}
                        filterElement={statusFilterTemplate}/>
                <Column header={__('Ultimo accesso', 'gepafin')}
                        filterField="lastLogin" dataType="date"
                        style={{ minWidth: '10rem' }}
                        body={dateLastAccessBodyTemplate} filter filterElement={dateFilterTemplate}/>
                {/*<Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>*/}
            </DataTable>
        </div>
    )
}

export default AllUsersTable;