import React from 'react';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';

const TextInput = ({
                       fieldName,
                       label,
                       control,
                       errors,
                       defaultValue,
                       config = {},
                       infoText = null,
                       inputgroup = false,
                       icon = null,
                       placeholder = '',
                       inputtype = 'text',
                       disabled = false,
                       onBlurFn = () => {
                       }
                   }) => {
    const input = <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={config}
        render={({ field, fieldState }) => (
            <InputText id={field.name}
                       disabled={disabled}
                       {...field}
                       value={field.value ? field.value : ''}
                       onBlur={onBlurFn}
                       type={inputtype}
                       placeholder={placeholder}
                       className={classNames({ 'p-invalid': fieldState.invalid })}/>
        )}/>
    return (
        <>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required || config.isRequired ? <span className="appForm__field--required">*</span> : null}
            </label>
            {inputgroup
                ? <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        {icon}
                    </span>
                    {input}
                </div>
                : input}
            {infoText ? <small>{infoText}</small> : null}
        </>)
}

export default TextInput;