import React, { useEffect, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { wrap } from 'object-path-immutable';
import { pathOr } from 'ramda';

// components
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

// tools
import uniqid from '../../../../../../helpers/uniqid';

const ElementSettingTableColumns = ({
                                        value,
                                        name,
                                        setDataFn,
                                        bandoStatus
                                    }) => {
    const [stateFieldData, setStateFieldData] = useState([]);
    const [rowsData, setRowsData] = useState([]);

    const removeItem = (index) => {
        const newData = stateFieldData.toSpliced(index, 1);
        setStateFieldData(newData);
    }

    const addNewItem = () => {
        setStateFieldData([...stateFieldData, { name: uniqid('o'), label: '', predefined: false }]);
    }

    const addNewRow = () => {
        const obj = stateFieldData
            .filter(o => o.predefined)
            .reduce((acc, cur) => {
                acc[cur.name] = ''
                return acc;
            }, {});
        setRowsData([...rowsData, obj]);
    }

    const removeRow = (index) => {
        const newRowsData = wrap(rowsData).del([index]).value();
        setRowsData(newRowsData);
    }

    const onInputChange = (e, index) => {
        const { value } = e.target;
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                o.label = value;
            }
            return o;
        })
        setStateFieldData(newData);
    }

    const onSubInputChange = (e, name, index) => {
        const { value } = e.target;
        const newRowsData = wrap(rowsData).set([index, name], value).value();
        setRowsData(newRowsData);
    }

    const setChecked = (value, index) => {
        let name = '';
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                o.predefined = value;
                name = o.name;
            }
            return o;
        });

        let newRowsData = [];

        if (value === false) {
            newRowsData = rowsData.map(o => wrap(o).set([name], '').value());
        } else {
            newRowsData = rowsData.map(o => wrap(o).set([name], '').value());
        }

        setRowsData(newRowsData);
        setStateFieldData(newData);
    }

    const properField = (item, i) => {
        return <>
            <InputText value={item.label} onInput={(e) => onInputChange(e, i)}/>
            <div className="flex-1">
                <span>{__('Predefinito?', 'gepafin')}</span>
                <InputSwitch
                    checked={item.predefined}
                    disabled={bandoStatus === 'PUBLISH'}
                    onChange={(e) => setChecked(e.value, i)}/>
            </div>
        </>
    }

    const properSubField = (item, i, name) => {
        return <InputText value={item[name]} onInput={(e) => onSubInputChange(e, name, i)}/>
    }

    useEffect(() => {
        const stateFieldData = pathOr([], ['stateFieldData'], value);
        setStateFieldData(stateFieldData);
        const rowsData = pathOr([], ['rowsData'], value);
        setRowsData(rowsData);
    }, []);

    useEffect(() => {
        setDataFn(name, {
            stateFieldData,
            rowsData
        });
    }, [stateFieldData, rowsData]);

    stateFieldData.filter(o => o.predefined)

    return (
        <>
            <div className="formElementSettings__repeater">
                {stateFieldData.map((o, i) => <div key={i} className="formElementSettings__repeaterItem">
                    <div className="p-inputgroup flex-1">
                        {properField(o, i)}
                        <Button icon="pi pi-times" disabled={bandoStatus === 'PUBLISH'} className="p-button-danger" onClick={() => removeItem(i)}/>
                    </div>
                </div>)}
                <Button type="button" disabled={bandoStatus === 'PUBLISH'} outlined label={__('Aggiungi', 'gepafin')} onClick={addNewItem}/>
            </div>
            {stateFieldData
                .filter(o => o.predefined)
                .map((o, i) => <div key={i} className="formElementSettings__repeaterItem">
                    <div className="formElementSettings__repeater formElementSettings__subRepeater">
                        <label>{__('Righe per colonna:', 'gepafin')} <strong>{o.label}</strong></label>
                        <div className="formElementSettings__repeater">
                            {rowsData.map((c, k) => {
                                return <div key={k} className="formElementSettings__repeaterItem">
                                    <div className="p-inputgroup flex-1">
                                        {properSubField(c, k, o.name)}
                                        <Button icon="pi pi-times"
                                                disabled={bandoStatus === 'PUBLISH'}
                                                className="p-button-danger"
                                                onClick={() => removeRow(k)}/>
                                    </div>
                                </div>
                            })}
                            <Button type="button"
                                    outlined
                                    disabled={bandoStatus === 'PUBLISH'}
                                    label={__('Aggiungi una riga', 'gepafin')}
                                    onClick={addNewRow}/>
                        </div>
                    </div>
                </div>)}
        </>
    )
}

export default ElementSettingTableColumns;