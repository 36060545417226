import React from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';

// store
//import { useStore } from '../../store';

// api
//import DashboardService from '../../service/dashboard-service';

// components
//import LatestBandiTable from './components/LatestBandiTable';
//import MyLatestSubmissionsTable from './components/MyLatestSubmissionsTable';
import { Button } from 'primereact/button';
import PreInstructorDomandeTable from './components/PreInstructorDomandeTable';

const DashboardPreInstructor = () => {
    const navigate = useNavigate();
    //const [mainStats, setMainStats] = useState({});

    const goToAllEvaluations = () => {
        navigate('/domande');
    }

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Dashboard', 'gepafin')}</h1>
            </div>

            {/*<div className="appPage__spacer"></div>

            <div className="appPageSection statsBigBadges">
                <h2>{__('Panoramica di Sistema', 'gepafin')}</h2>
                <div className="statsBigBadges__grid">
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Domande attive', 'gepafin')}</span>
                        <span>{getStatValue('numberOfApplications', 0)}</span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Bandi osservati', 'gepafin')}</span>
                        <span>{getStatValue('numberOfCalls', 0)}</span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Documenti da integrare', 'gepafin')}</span>
                        <span>{getStatValue('numberOfIntegratedDocuments', 0)}</span>
                    </div>
                </div>
            </div>*/}

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Coda di lavoro', 'gepafin')}</h2>
                <PreInstructorDomandeTable/>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni rapide', 'gepafin')}</span>
            </div>

            <div className="appPageSection">
                <div className="appPageSection__actions">
                    <Button
                        onClick={goToAllEvaluations}
                        label={__('Tutte le domande', 'gepafin')} icon="pi pi-arrow-right" iconPos="right"/>
                </div>
            </div>
        </div>
    )
}

export default DashboardPreInstructor;