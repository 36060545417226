import React, { useRef } from 'react'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../ItemTypes';
import uniqid from '../../../../helpers/uniqid';
import { storeSet } from '../../../../store';


const BuilderElementItem = ({ dbId, name, label, description = '' }) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.FIELD,
        item: () => {
            const newId = uniqid();
            storeSet.main.draggingElementId(newId);
            return { name, dbId, id: newId, index: -1 }
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()

            if (monitor.didDrop()) {
                if (item && dropResult) {
                    return item;
                }
            } else {
                storeSet.main.removeElement(item.id);
                storeSet.main.draggingElementId(0);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))

    const opacity = isDragging ? 0.4 : 1
    drag(ref);

    return (
        <div
            ref={ref}
            title={description}
            className="formBuilder__elementItem"
            style={{ opacity }}>
            {label}
        </div>
    )
}

export default BuilderElementItem;