import React, { useState } from 'react';
import { __ } from '@wordpress/i18n';

// tools
import set404FromErrorResponse from '../../../../helpers/set404FromErrorResponse';

// api
import ApplicationService from '../../../../service/application-service';

// components
import { Button } from 'primereact/button';

const DownloadApplicationArchive = ({ applicationId = 0 }) => {
    const [loading, setLoading] = useState(false);

    const onClickDownload = () => {
        setLoading(true);
        ApplicationService.downloadCompleteZip(applicationId, getCompleteZipCallback, errCompleteZipCallback);
    }

    const getCompleteZipCallback = (data) => {
        const file = new Blob([data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `application-${applicationId}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
    }

    const errCompleteZipCallback = (data) => {
        set404FromErrorResponse(data);
        setLoading(false);
    }

    return (applicationId && applicationId !== 0
            ? <Button
                type="button"
                disabled={loading}
                onClick={onClickDownload}
                label={__('Scarica ZIP', 'gepafin')}
                icon="pi pi-download"
                iconPos="right"/> : null
    )
}

export default DownloadApplicationArchive;