import React from 'react';

const PageNotFound = () => {
    return(
        <div className="error404">
            <div>
                <h1>Error 404</h1>
                <span>Pagina non trovata</span>
            </div>
        </div>
    )
}

export default PageNotFound;