import { head } from 'ramda';
import { mimeTypes } from '../configData';

const getPropeMimeLabels = (accept) => {
    return accept
        .map(v => {
            const found = head(mimeTypes.filter(o => o.code.includes(v)));
            let res = v;

            if (found) {
                res = found.name;
            }

            return res;
        })
        .join(', ');
}

export default getPropeMimeLabels;