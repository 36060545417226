import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class FaqItemService {

    static addQuestion = (id, body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/faq/call/${id}`, body, callback, errCallback, queryParams);
    };
}
