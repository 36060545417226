import { storeGet, storeSet } from '../store';
import * as Sentry from '@sentry/browser';

const LOCAL_DEVELOPMENT = process.env.REACT_APP_LOCAL_DEVELOPMENT;

export class NetworkService {
    static TOKEN_KEY
    static REFRESH_TOKEN_KEY

    static logApiError = (endpoint, status = 0, resp = {}) => {
        if (status === 500) {
            if (LOCAL_DEVELOPMENT !== '1') {
                try {
                    Sentry.init({
                        dsn: 'https://e7b2134f7d816f663bb83e51b106a694@o4508381921738752.ingest.de.sentry.io/4508381935501392',
                        environment: process.env.NODE_ENV || 'development'
                    });

                    const error = new Error(`Status ${status}`);
                    Sentry.captureException(`Error in endpoint: ${endpoint}`, {
                        level: 'error',
                        extra: {
                            originalError: error,
                            details: resp
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        } else if (status === 403) {
            storeSet.main.token('');
            const { pathname } = window.location;
            if (!['/login', '/loginadmin', '/reset-password', '/registration'].includes(pathname)) {
                window.location.replace('/login');
            }
        }
    }

    static post = (url, body, callback, errorCallback, queryParams) => {

        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                this.logApiError(url, status);
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599) {
                    errorCallback(data.response)
                    this.logApiError(url, data.status, data.response);
                } else {
                    callback(data.response)
                }
            })
            .catch(err => errorCallback(err));
    };

    static postMultiPart = (url, body, callback, errorCallback, queryParams) => {

        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                //'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: body
        })
            .then(async response => {
                let status = response.status;
                this.logApiError(url, status);
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599) {
                    errorCallback(data.response)
                    this.logApiError(url, data.status, data.response);
                } else {
                    callback(data.response)
                }
            })
            .catch(err => errorCallback(err));
    };

    static postBlob = (url, body, callback, errorCallback, queryParams) => {

        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                this.logApiError(url, status);
                return { response: await response.blob(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599) {
                    errorCallback(data.response)
                    this.logApiError(url, data.status, data.response);
                } else {
                    callback(data.response)
                }
            })
            .catch(err => errorCallback(err));
    };

    static unauthorizedPost = (url, body, callback, errorCallback, queryParams) => {
        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                this.logApiError(url, status);
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599) {
                    errorCallback(data.response)
                    this.logApiError(url, data.status, data.response);
                } else {
                    callback(data.response)
                }
            })
            .catch(err => errorCallback(err));
    };

    static put = (url, body, callback, errorCallback, queryParams = null) => {
        if (queryParams) {
            url += '?'

            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                this.logApiError(url, status);
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599) {
                    errorCallback(data.response)
                    this.logApiError(url, data.status, data.response);
                } else {
                    callback(data.response)
                }
            })
            .catch(err => errorCallback(err));
    };

    static isNotBlank(value) {
        return value !== null && value !== undefined && value !== ''
    }

    static get = (url, callback, errorCallback, queryParams = null) => {
        if (queryParams) {
            url += '?'

            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            signal: AbortSignal.timeout(5000)
        })
            .then(async response => {
                let status = response.status;
                this.logApiError(url, status);
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599) {
                    errorCallback(data.response);
                    this.logApiError(url, data.status, data.response);
                } else {
                    callback(data.response)
                }
            })
            .catch(err => {
                errorCallback(err)
            });

    };

    static getBlob = (url, callback, errorCallback, queryParams) => {

        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
        })
            .then(async response => {
                let status = response.status;
                this.logApiError(url, status);
                return { response: await response.blob(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599) {
                    errorCallback(data.response)
                    this.logApiError(url, data.status, data.response);
                } else {
                    callback(data.response)
                }
            })
            .catch(err => errorCallback(err));
    };

    static delete = (url, body, callback, errorCallback, queryParams = null) => {
        if (queryParams) {
            let params = '?'

            for (let i = 0; i < queryParams.length; i++) {
                params += queryParams[i][0] + '=' + queryParams[i][1]
                if (queryParams.length !== i + 1)
                    params += '&'
                url += params
                params = ''
            }

        }
        fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                this.logApiError(url, status);
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599) {
                    errorCallback(data.response)
                    this.logApiError(url, data.status, data.response);
                } else {
                    callback(data.response)
                }
            })
            .catch(err => errorCallback(err));

    };
}
