import React, { useRef, useState, useEffect } from 'react';
import { __, sprintf } from '@wordpress/i18n';
import { useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { isEmpty } from 'ramda';
import { useNavigate } from 'react-router-dom';

// tools
import AuthenticationService from '../../service/authentication-service';

// store
import { storeSet, useStore } from '../../store';

// components
import FormField from '../../components/FormField';
import LogoIcon from '../../icons/LogoIcon';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';

const APP_HUB_ID = process.env.REACT_APP_HUB_ID;

const LoginAdmin = () => {
    const navigate = useNavigate();
    const token = useStore().main.token();
    const [loading, setLoading] = useState(false);
    const errorMsgs = useRef(null);
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onChange' });

    const gotToResetPassword = () => {
        navigate('/reset-password');
    }

    const onSubmit = (formData) => {
        errorMsgs.current.clear();
        setLoading(true);
        const request = {
            ...formData,
            hubUuid: APP_HUB_ID,
            rememberMe: true
        }

        AuthenticationService.login(request, loginCallback, loginError);
    };

    const loginCallback = (data) => {
        if (data.status === 'SUCCESS') {
            storeSet.main.setAuthData({
                token: data.data.token,
                userData: data.data.user
            });
        } else {
            errorMsgs.current.show([
                { sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true }
            ]);
        }
        setLoading(false);
    }

    const loginError = (err) => {
        errorMsgs.current.show([
            { sticky: true, severity: 'error', summary: '',
                detail: sprintf(__('%s', 'gepafin'), err.message),
                closable: true }
        ]);
        setLoading(false);
    }

    useEffect(() => {
        if (!isEmpty(token)) {
            setLoading(true);
            window.location.replace('/')
        }
    }, [token]);

    return (
        <div className={classNames(['appPage', 'appPageLogin'])}>
            <div className="appPageLogin__wrapper">
                <LogoIcon/>

                <h1>{__('Accedi o Registrati', 'gepafin')}</h1>

                <Messages ref={errorMsgs}/>

                <div className="appPage__spacer"></div>

                <form className="appForm" onSubmit={handleSubmit(onSubmit)}>
                    <FormField
                        type="textinput"
                        fieldName="email"
                        label={__('Email', 'gepafin')}
                        control={control}
                        errors={errors}
                        config={{ required: __('È obbligatorio', 'gepafin') }}
                        placeholder="sample@example.com"
                    />

                    <FormField
                        type="textinput"
                        inputtype="password"
                        fieldName="password"
                        label={__('Password', 'gepafin')}
                        control={control}
                        errors={errors}
                        config={{ required: __('È obbligatorio', 'gepafin') }}
                    />

                    <Button
                        label={__('Accedi', 'gepafin')}
                        disabled={loading}/>

                    <Button
                        label={__('Password dimenticata?', 'gepafin')}
                        link onClick={gotToResetPassword}/>
                </form>
            </div>
        </div>
    )
}

export default LoginAdmin;