import React from 'react';
import { __ } from '@wordpress/i18n';
import { Link } from 'react-router-dom';
import { isEmpty } from 'ramda';

// store
import { useStore } from '../../store';

// components
import AllBandiAccordion from './components/AllBandiAccordion';
import ErrorBoundary from '../../components/ErrorBoundary';

const BandiBeneficiario = () => {
    const chosenCompanyId = useStore().main.chosenCompanyId();

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Bandi disponibili', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            {isEmpty(chosenCompanyId) || chosenCompanyId === 0
                ? <>
                    <div className="appPageSection__message warning">
                        <i className="pi pi-exclamation-triangle"></i>
                        <span className="summary">{__('Attenzione', 'gepafin')}</span>
                        <span>
                            {__('Per applicare ai bandi devi Registare un Azienda clicca', 'gepafin')}
                            <Link to={`/agguingi-azienda`} style={{marginLeft: '0.5ch'}}>{__('qua', 'gepafin')}</Link>
                        </span>
                    </div>
                    <div className="appPage__spacer"></div>
                </> : null}

            <div className="appPageSection">
                <ErrorBoundary><AllBandiAccordion/></ErrorBoundary>
            </div>
        </div>
    )
}

export default BandiBeneficiario;