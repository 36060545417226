import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { uniq, is } from 'ramda';

// tools
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';

// store
import { useStore } from '../../../../store';

// api
import ApplicationService from '../../../../service/application-service';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Link } from 'react-router-dom';
import translationStrings from '../../../../translationStringsForComponents';

const DraftApplicationsTable = () => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        setLocalAsyncRequest(true);
        ApplicationService.getApplications(getApplCallback, errGetApplCallback, [
            ['statuses', ['DRAFT', 'AWAITING', 'READY']]
        ])
    }, [chosenCompanyId]);

    const getApplCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (is(Array, data.data)) {
                setItems(getFormattedBandiData(data.data));
                setStatuses(uniq(items.map(o => o.status)))
                initFilters();
            }
        }
        setLocalAsyncRequest(false);
    }

    const errGetApplCallback = (data) => {
        setLocalAsyncRequest(false);
    }

    const getFormattedBandiData = (data) => {
        return [...(data || [])].map((d) => {
            d.callEndDate = new Date(d.callEndDate);
            d.modifiedDate = new Date(d.modifiedDate);

            return d;
        });
    };

    /*const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };*/

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            callTitle: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            companyName: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            modifiedDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            callEndDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined
                        onClick={clearFilter}/>
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel} className="p-column-filter"
                         showClear/>;
    };

    const progressBodyTemplate = (options) => {
        return <ProgressBar value={options.progress} color={'#64748B'}></ProgressBar>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const actionsBodyTemplate = (rowData) => {
        return <Link to={`/domande/${rowData.id}`}>
            <Button severity="info" label={__('Anteprima', 'gepafin')} icon="pi pi-eye" size="small"
                    iconPos="right"/>
        </Link>
    }

    const header = renderHeader();

    return (
        <div className="appPageSection__table">
            <DataTable value={items} paginator showGridlines rows={10} loading={localAsyncRequest} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       emptyMessage={translationStrings.emptyMessage}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="id" header={__('ID domanda', 'gepafin')}
                        sortable
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="callTitle" header={__('Bando', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="companyName" header={__('Azienda', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                {/*<Column header={__('Scadenza', 'gepafin')} filterField="callEndDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateEndBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column header={__('Ultima modifica', 'gepafin')} filterField="modifiedDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateModifyBodyTemplate} filter filterElement={dateFilterTemplate}/>*/}
                <Column field="status" header={__('Stato', 'gepafin')} filterMenuStyle={{ width: '14rem' }}
                        style={{ minWidth: '7rem' }} body={statusBodyTemplate}
                        filter
                        filterElement={statusFilterTemplate}/>
                <Column header={__('Progressi', 'gepafin')}
                        style={{ minWidth: '10rem' }} field="progress" body={progressBodyTemplate}/>
                <Column header={__('Azioni', 'gepafin')}
                        style={{ minWidth: '10rem' }}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default DraftApplicationsTable;