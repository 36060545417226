import React, { useEffect, useState, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { __ } from '@wordpress/i18n';
import { pathOr, isEmpty, isNil } from 'ramda';
import { wrap } from 'object-path-immutable';
import equal from 'fast-deep-equal';

//components
import { Button } from 'primereact/button';
import RenderTable from './RenderTable';
import { klona } from 'klona';
import { nonEmptyTables } from '../../../../helpers/validators';

const Table = ({
                   fieldName,
                   setDataFn,
                   label,
                   register,
                   errors,
                   config = {},
                   defaultValue = [],
                   tableColumns = []
               }) => {
    const [columnsCfg, setColumnsCfg] = useState([]);
    const [rowsCfg, setRowsCfg] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState(null);
    const [shouldDisableNewRows, setShouldDisableNewRows] = useState(false);
    const [rowIndexToDelete, rowRowIndexToDelete] = useState(null);

    const addNewRow = () => {
        const obj = columnsCfg
            .reduce((acc, cur) => {
                acc[cur.name] = ''
                return acc;
            }, {});
        const newRowsData = [...rows, obj];
        updateRows(newRowsData);
    }

    const removeRow = (index) => {
        rowRowIndexToDelete(index);
    }

    const updateRows = useCallback((data) => {
        setRows(data);
        setDataFn(fieldName, data, { shouldValidate: true });
    }, [rows, defaultValue]);

    const properConfig = (config) => {
        let newConfig = klona(config);
        if (config.validate && config.validate.nonEmptyTables) {
            newConfig = wrap(newConfig)
                .set(['validate', 'nonEmptyTables'], (v) => nonEmptyTables(v, tableColumns))
                .value();
        }

        return newConfig;
    }

    useEffect(() => {
        if (!isNil(rowIndexToDelete)) {
            const newRowsData = wrap(rows).del([rowIndexToDelete]).value();
            updateRows(newRowsData);
        }
        rowRowIndexToDelete(null);
    }, [rowIndexToDelete]);

    useEffect(() => {
        let shouldDisableNewRows = false;

        let newColumns = columnsCfg.map((o) => {
            const item = {
                accessorKey: o.name,
                header: () => o.label,
                footer: (props) => props.column.id
            }

            if (o.predefined) {
                shouldDisableNewRows = true;
                item.cell = (info) => {
                    return info.getValue();
                }
            }

            return item;
        });

        setShouldDisableNewRows(shouldDisableNewRows);

        if (!shouldDisableNewRows && !isEmpty(newColumns)) {
            newColumns.push({
                accessorKey: 'actions',
                header: () => '',
                footer: (props) => props.column.id,
                cell: ({row: { index }}) => <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    label=""
                    onClick={() => removeRow(index)}/>
            })
        }

        setColumns(newColumns);
    }, [columnsCfg]);

    useEffect(() => {
        setRows(rowsCfg);
    }, [rowsCfg]);

    useEffect(() => {
        const stateFieldData = pathOr([], ['stateFieldData'], tableColumns);
        const obj = stateFieldData
            .reduce((acc, cur) => {
                acc[cur.name] = ''
                return acc;
            }, {});
        let rowsData = pathOr([obj], ['rowsData'], tableColumns);
        rowsData = isEmpty(rowsData) ? [obj] : rowsData;
        setColumnsCfg(stateFieldData);
        setRowsCfg(rowsData);
    }, [tableColumns]);

    useEffect(() => {
        if (!equal(rows, defaultValue)) {
            setRows(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        setRows(defaultValue);
        register(fieldName, properConfig(config));
    }, []);

    return (
        <>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required || config.isRequired || (config.validate && config.validate.nonEmptyTables)
                ? <span className="appForm__field--required">*</span> : null}
            </label>
            {rows ? <RenderTable columns={columns} data={rows} setRowsFn={updateRows}/> : null}
            {!isEmpty(columns) && !shouldDisableNewRows
                ? <div className="addNewTableRow" onClick={addNewRow}>{__('Aggiungi una riga', 'gepafin')}</div>
                : null}
        </>)
}

export default Table;