import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

const rootEl = document.getElementById('root');
const rootReact = createRoot(rootEl);

rootReact.render(
    <App/>
);
/*rootReact.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);*/
