import React, { useEffect, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { isEmpty, head } from 'ramda';
import { klona } from 'klona';
import { useDebounce } from 'primereact/hooks';
import { useNavigate } from 'react-router-dom';

// store
import { storeSet, useStore, storeGet } from '../../store';

// components
import { Messages } from 'primereact/messages';
import FormField from '../../components/FormField';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import BlockingOverlay from '../../components/BlockingOverlay';

// api
import CompanyService from '../../service/company-service';

// tools
import { isPIVA, isEmail, isEmailPEC, isCodiceFiscale } from '../../helpers/validators';
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';

//const APP_EVALUATION_FLOW_ID = process.env.REACT_APP_EVALUATION_FLOW_ID;

const AddCompany = () => {
    const navigate = useNavigate();
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const infoMsgs = useRef(null);
    const [, debouncedPivaValue, setInputPiva] = useDebounce('', 1000);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        trigger
    } = useForm({
        defaultValues: {},
        mode: 'onChange'
    });
    const isPiva = watch('vatNumber');
    //const isPiva = watch('vatNumber');

    const setEmptyValues = () => {
        const formData = {
            cap: '',
            pec: '',
            email: '',
            city: '',
            codiceFiscale: '',
            address: '',
            companyName: ''
        }
        Object.keys(formData).map(k => setValue(k, formData[k]));
    }

    const onSubmit = (formData) => {
        infoMsgs.current.clear();
        storeSet.main.setAsyncRequest();

        CompanyService.createCompany(formData, updateCallback, updateError);
    };

    const updateCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const company = klona(data.data);
            const companies = storeGet.main.companies();
            const existingCompany = head(companies.filter(o => o.id === company.id));
            let newCompanies = [];

            if (existingCompany) {
                newCompanies = companies.map(o => o.id === company.id ? company : o)
            } else {
                newCompanies = [...companies, company];
                storeSet.main.chosenCompanyId(company.id);
            }

            storeSet.main.companies(newCompanies);

            if (company.isLegalRepresentant) {
                navigate('/');
            } else {
                navigate('/profilo-aziendale');
            }

        }
        storeSet.main.unsetAsyncRequest();
    }

    const updateError = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const checkVatNumber = (value) => {
        infoMsgs.current.clear();
        const isValid = isPIVA(value);

        if (isValid) {
            storeSet.main.setAsyncRequest();
            CompanyService.checkVat(checkVatCallback, errCheckVatCallback, [['vatNumber', value]])
        } else {
            setEmptyValues();
        }
    }

    const checkVatCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const resp = data.data.data;
            if (!isEmpty(resp)) {
                const {
                    cap, cf, denominazione, piva, indirizzo, comune, dettaglio: { pec }
                } = resp;

                const formData = {
                    cap,
                    pec,
                    email: pec,
                    city: comune,
                    codiceFiscale: cf ? cf : piva,
                    address: indirizzo,
                    vatNumber: piva,
                    companyName: denominazione
                }
                Object.keys(formData).map(k => setValue(k, formData[k]));
            }
            //setData(getFormattedBandiData(data.data));
        } else {
            setEmptyValues();
        }
        trigger();
        storeSet.main.unsetAsyncRequest();
    }

    const errCheckVatCallback = (data) => {
        setEmptyValues();
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    /*const shouldDisableFiscalCode = () => {
        const formData = getValues();
        return !formData.type || formData.type && formData.type === 'giuridica';
    }*/

    useEffect(() => {
        setInputPiva(isPiva);
    }, [isPiva]);

    useEffect(() => {
        checkVatNumber(debouncedPivaValue);
    }, [debouncedPivaValue])

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Aggiungi azienda', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>
            <Messages ref={infoMsgs}/>

            <form id="companyForm" className="appForm" onSubmit={handleSubmit(onSubmit)}>
                <BlockingOverlay shouldDisplay={isAsyncRequest}/>
                <div className="appPageSection">
                    <h2>{__('Informazioni aziendali', 'gepafin')}</h2>
                    <p>
                        {__('Inserisci P.IVA e i dati aziendali verranno inseriti automaticamente per le aziende già esistenti', 'gepafin')}
                    </p>

                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            /*disabled={true}*/
                            fieldName="companyName"
                            label={__('Ragione Sociale', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            placeholder="Nome di azienda"
                        />
                    </div>

                    {/*{APP_EVALUATION_FLOW_ID === '2'
                        ? <div className="appForm__cols">
                            <FormField
                                type="radio"
                                fieldName="type"
                                label={__('Type', 'gepafin')}
                                control={control}
                                errors={errors}
                                config={{ required: __('È obbligatorio', 'gepafin') }}
                                defaultValue="giuridica"
                                options={[
                                    { name: 'giuridica', label: 'Giuridica' },
                                    { name: 'fisica', label: 'Fisica' },
                                ]}
                            />
                        </div> : null}*/}

                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            fieldName="vatNumber"
                            label={__('P.IVA', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    isPIVA
                                }
                        }}
                        />

                        <FormField
                            type="textinput"
                            fieldName="codiceFiscale"
                            label={__('Codice fiscale', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    validCF: (value) => isCodiceFiscale(value) || isPIVA(value)
                                }
                        }}
                        />
                        {/*{APP_EVALUATION_FLOW_ID === '1'
                            ? <FormField
                                type="textinput"
                                disabled
                                fieldName="codiceFiscale"
                                label={__('Codice fiscale', 'gepafin')}
                                control={control}
                                errors={errors}
                                config={{ required: __('È obbligatorio', 'gepafin') }}
                            />
                            : <FormField
                                type="textinput"
                                disabled={shouldDisableFiscalCode()}
                                fieldName="codiceFiscale"
                                label={__('Codice fiscale', 'gepafin')}
                                control={control}
                                errors={errors}
                                config={{ required: __('È obbligatorio', 'gepafin') }}
                            />}*/}
                    </div>

                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            /*disabled={true}*/
                            fieldName="pec"
                            label={__('Email PEC', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    isEmailPEC: isEmailPEC
                                }
                            }}
                        />

                        <FormField
                            type="textinput"
                            /*disabled={true}*/
                            fieldName="email"
                            label={__('Email', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    isEmail: isEmail
                                }
                            }}
                        />

                        {/*<FormField
                            type="textinput"
                            fieldName="phoneNumber"
                            label={__('Telefono', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                        />*/}
                    </div>

                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            /*disabled={true}*/
                            fieldName="address"
                            label={__('Indirizzo', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                        />

                        <FormField
                            type="textinput"
                            /*disabled={true}*/
                            fieldName="city"
                            label={__('Città', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                        />

                        <FormField
                            type="textinput"
                            /*disabled={true}*/
                            fieldName="cap"
                            label={__('CAP', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                        />
                    </div>

                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            fieldName="contactName"
                            label={__('Nome di referente aziendale', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                        />

                        <FormField
                            type="textinput"
                            fieldName="contactEmail"
                            label={__('Email di referente aziendale', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    isEmail: isEmail
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="appPageSection">
                    <FormField
                        type="switch"
                        fieldName="isLegalRepresentant"
                        label={__('Sei il Rappresentante Legale dell’azienda?', 'gepafin')}
                        control={control}
                        errors={errors}
                        onLabel={__('Si', 'gepafin')}
                        offLabel={__('No', 'gepafin')}
                    />
                </div>
            </form>

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni rapide', 'gepafin')}</span>
            </div>

            <div className="appPageSection">
                <div className="appPageSection__actions">
                    <Button
                        form="companyForm"
                        disabled={isAsyncRequest}
                        label={__('Salva modifiche', 'gepafin')}
                        icon="pi pi-check" iconPos="right"/>
                </div>
            </div>

        </div>
    )

}

export default AddCompany;