import React from 'react';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import { Editor } from 'primereact/editor';
import BlockingOverlay from '../../../BlockingOverlay';

const Wysiwyg = ({
                     fieldName,
                     label,
                     control,
                     rows = 3,
                     errors,
                     defaultValue,
                     config = {},
                     infoText = null,
                     placeholder = '',
                     disabled = false
                 }) => {

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-header" value="2"></button>
                <button className="ql-blockquote"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
        );
    };

    const header = renderHeader();

    return (
        <>
            <BlockingOverlay shouldDisplay={disabled}/>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required || config.isRequired ? <span className="appForm__field--required">*</span> : null}
            </label>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={defaultValue}
                rules={config}
                render={({ field, fieldState }) => (
                    <Editor
                        id={field.name}
                        readOnly={disabled}
                        {...field}
                        headerTemplate={header}
                        placeholder={placeholder}
                        onTextChange={(e) => field.onChange(e.htmlValue)}
                        style={{ height: 80 * rows }}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                )}/>
            {infoText ? <small>{infoText}</small> : null}
        </>)
}

export default Wysiwyg;