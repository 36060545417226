import React, { useRef, useState, useEffect } from 'react';
import { __, sprintf } from '@wordpress/i18n';
import { useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { isEmpty } from 'ramda';
import { useSearchParams } from 'react-router-dom';

// api
import AuthenticationService from '../../service/authentication-service';

// tools
import { isEmail } from '../../helpers/validators';

// store
import { storeSet, useStore } from '../../store';

// components
import FormField from '../../components/FormField';
import LogoIcon from '../../icons/LogoIcon';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import getDateFromISOstring from '../../helpers/getDateFromISOstring';

const APP_HUB_ID = process.env.REACT_APP_HUB_ID;

const Registration = () => {
    const token = useStore().main.token();
    const [loading, setLoading] = useState(false);
    const [originalDateOfBirth, setOriginalDateOfBirth] = useState('')
    const [visibleTerms, setVisibleTerms] = useState(false);
    const errorMsgs = useRef(null);
    let [searchParams] = useSearchParams();
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues
    } = useForm({ mode: 'onChange' });
    const values = getValues();
    const { origin } = window.location;
    const urlTermsPdf = `${origin}/loaded-files/termini-e-condizioni.pdf`;

    const onSubmit = (formData) => {
        errorMsgs.current.clear();
        const temp_token = searchParams.get('temp_token');
        setLoading(true);
        const newFormData = {
            ...formData,
            dateOfBirth: originalDateOfBirth,
            hubUuid: APP_HUB_ID
        }

        AuthenticationService.registerUser(newFormData, regCallback, regError, [
            ['tempToken', temp_token]
        ]);
    };

    const regCallback = (data) => {
        if (data.status === 'SUCCESS') {
            storeSet.main.setAuthData({
                token: data.data.token,
                userData: data.data.user
            });
        } else {
            errorMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.data.message,
                    closable: true
                }
            ]);
        }
        setLoading(false);
    }

    const regError = (err) => {
        errorMsgs.current.show([
            {
                sticky: true, severity: 'error', summary: '',
                detail: sprintf(__('%s', 'gepafin'), err.message),
                closable: true
            }
        ]);
        setLoading(false);
    }

    const validateCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const { codiceFiscale, firstName, lastName, dateOfBirth } = data.data;
            setOriginalDateOfBirth(dateOfBirth);
            setValue('codiceFiscale', codiceFiscale);
            setValue('firstName', firstName);
            setValue('lastName', lastName);
            setValue('dateOfBirth', getDateFromISOstring(dateOfBirth));
        } else {
            errorMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        }
        setLoading(false);
    }

    const validateError = (err) => {
        errorMsgs.current.show([
            {
                sticky: true, severity: 'error', summary: '',
                detail: sprintf(__('%s', 'gepafin'), err.message),
                closable: true
            }
        ]);
        setLoading(false);
    }

    const disableAllChecks = () => {
        setValue('marketing', false);
        setValue('offers', false);
        setValue('thirdParty', false);
    }

    const enableAllChecks = () => {
        setValue('marketing', true);
        setValue('offers', true);
        setValue('thirdParty', true);
    }

    const openTermsDoc = (e) => {
        e.preventDefault();
        setVisibleTerms(true);
    }

    const hideTerms = () => {
        setVisibleTerms(false);
    }

    useEffect(() => {
        if (!isEmpty(token)) {
            setLoading(true);
            window.location.replace('/')
        }
    }, [token]);

    useEffect(() => {
        const temp_token = searchParams.get('temp_token');

        if (temp_token && !isEmpty(temp_token)) {
            AuthenticationService.validateNewUser(temp_token, validateCallback, validateError);
        }
    }, [searchParams]);

    return (
        <div className={classNames(['appPage', 'appPageLogin'])}>
            <div className="appPageLogin__wrapper">
                <LogoIcon/>

                <h1>{__('Completamento anagrafica personale', 'gepafin')}</h1>
                <span>{__('Per favore, inserisci i tuoi dati.', 'gepafin')}</span>

                <div className="appPage__spacer"></div>

                <Messages ref={errorMsgs}/>

                <form className="appForm" onSubmit={handleSubmit(onSubmit)}>
                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            disabled={true}
                            fieldName="firstName"
                            label={__('Nome', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            placeholder="Francesco"
                        />

                        <FormField
                            type="textinput"
                            disabled={true}
                            fieldName="lastName"
                            label={__('Cognome', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            placeholder="Molini"
                        />
                    </div>

                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            disabled={true}
                            fieldName="codiceFiscale"
                            label={__('Codice fiscale', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin')
                            }}
                            placeholder="ABC1234"
                        />

                        <FormField
                            type="textinput"
                            disabled={true}
                            fieldName="dateOfBirth"
                            label={__('Data di nascita', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            placeholder=""
                        />
                    </div>

                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            fieldName="email"
                            label={__('Email', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    isEmail
                                }
                            }}
                            placeholder="sample@example.com"
                        />

                        <FormField
                            type="textinput"
                            fieldName="phoneNumber"
                            label={__('Telefono', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                pattern: /^[\d]{5,12}$/
                            }}
                            placeholder=""
                        />
                    </div>

                    <Panel
                        headerTemplate={(options) => {
                            return (
                                <div className={classNames(['p-panel-header', 'reverseSwitchField'])}>
                                    <FormField
                                        type="switch"
                                        fieldName="privacy"
                                        label={__('Ho letto e accetto l’Informativa sulla Privacy (Necessario)', 'gepafin')}
                                        control={control}
                                        errors={errors}
                                        defaultValue={values['privacy']}
                                        onLabel={''}
                                        offLabel={''}
                                        config={{
                                            required: __('È obbligatorio', 'gepafin'),
                                        }}
                                    />
                                    <div>
                                        {options.togglerElement}
                                    </div>
                                </div>
                            );
                        }}
                        toggleable>
                        <p className="m-0">
                            {__('Dichiaro di aver preso visione, prima dell\'accesso al portale https://bandi.gepafin.it, dell\' "Informativa Privacy" all\'interno dell\'Appendice 10 dell\'Avviso secondo il Regolamento UE 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personale, nonché alla libera circolazione di tali dati e che abroga la Direttiva 95/46 CE.', 'gepafin')}
                        </p>
                    </Panel>

                    <Panel
                        headerTemplate={(options) => {
                            return (
                                <div className={classNames(['p-panel-header', 'reverseSwitchField'])}>
                                    <FormField
                                        type="switch"
                                        fieldName="terms"
                                        label={__('Ho letto e accetto Termini e condizioni (Necessario)', 'gepafin')}
                                        control={control}
                                        errors={errors}
                                        defaultValue={values['terms']}
                                        onLabel={''}
                                        offLabel={''}
                                        config={{
                                            required: __('È obbligatorio', 'gepafin'),
                                        }}
                                    />
                                    <div>
                                        {options.togglerElement}
                                    </div>
                                </div>
                            );
                        }}
                        toggleable>
                        <p className="m-0">
                            <a href={urlTermsPdf} onClick={openTermsDoc}>{__('Termini e condizioni', 'gepafin')}</a>
                        </p>
                    </Panel>

                    <Panel
                        header={__('Altri consensi (facoltativo)', 'gepafin')}
                        toggleable>
                        <div className="appForm__col">
                            <div className={classNames(['appForm__row', 'reverseSwitchField'])}>
                                <FormField
                                    type="switch"
                                    fieldName="marketing"
                                    label={''}
                                    control={control}
                                    errors={errors}
                                    defaultValue={values['marketing']}
                                    onLabel={''}
                                    offLabel={''}
                                />
                                <div>
                                    {__('Invio di materiale pubblicitario, vendita diretta, compimento di ricerche di mercato o comunicazione commerciale riguardanti promozione e vendita di prodotti e servizi della Gepafin, mediante modalità di contatto automatizzate (posta elettronica, PEC, messaggi tramite canali informatici, network ed applicazioni web) e tradizionali (come posta cartacea e chiamate telefoniche con operatore)', 'gepafin')}
                                </div>
                            </div>
                            <div className={classNames(['appForm__row', 'reverseSwitchField'])}>
                                <FormField
                                    type="switch"
                                    fieldName="offers"
                                    label={''}
                                    control={control}
                                    errors={errors}
                                    defaultValue={values['offers']}
                                    onLabel={''}
                                    offLabel={''}
                                />
                                <div>
                                    {__('Elaborazione, in forma elettronica, dei dati relativi ai rapporti e servizi forniti, per l’analisi di comportamenti e preferenze della clientela, da utilizzare a scopo commerciale, per la individuazione ed offerta di prodotti e servizi di suo interesse', 'gepafin')}
                                </div>
                            </div>
                            <div className={classNames(['appForm__row', 'reverseSwitchField'])}>
                                <FormField
                                    type="switch"
                                    fieldName="thirdParty"
                                    label={''}
                                    control={control}
                                    errors={errors}
                                    defaultValue={values['thirdParty']}
                                    onLabel={''}
                                    offLabel={''}
                                />
                                <div>
                                    {__('Comunicazione dei miei dati ad altre società in ambito bancario, finanziario od assicurativo e enti pubblici che li tratteranno per invio di materiale pubblicitario, vendita diretta, compimento di ricerche di mercato o comunicazione commerciale riguardanti loro prodotti o servizi, mediante le modalità automatizzate e tradizionali di comunicazione sopra indicate', 'gepafin')}
                                </div>
                            </div>
                            <div className="appForm__cols">
                                <Button
                                    type="button"
                                    severity="info"
                                    onClick={disableAllChecks}
                                    outlined
                                    label={__('Nega tutti', 'gepafin')}/>

                                <Button
                                    type="button"
                                    severity="info"
                                    onClick={enableAllChecks}
                                    label={__('Attiva tutti', 'gepafin')}/>
                            </div>
                        </div>
                    </Panel>

                    <Button
                        label={__('Invia', 'gepafin')}
                        disabled={loading}/>
                </form>

                <Dialog header={__('Termini e condizioni', 'gepafin')} visible={visibleTerms} style={{
                    width: '70vw',
                    minHeight: 500
                }} onHide={hideTerms}>
                    <iframe src={urlTermsPdf} title={__('Termini e condizioni', 'gepafin')}></iframe>
                </Dialog>
            </div>
        </div>
    )
}

export default Registration;