const getNumberWithCurrency = (value, currency = 'EUR') => {
    const formatter = Intl.NumberFormat('it-IT', {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency
    })

    return value ? formatter.format(value) : formatter.format(0)
}

export default getNumberWithCurrency;