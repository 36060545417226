import React, { useEffect, useState } from 'react';
import {
    ReactFlow,
    Background
} from '@xyflow/react';
import { isEmpty } from 'ramda';

import '@xyflow/react/dist/style.css';

// store
import { useStore, storeSet, storeGet } from '../../store';

// nodes
import NodeInitialForm from './components/NodeInitialForm';
import NodeIntermediateForm from './components/NodeIntermediateForm';

const nodeTypes = {
    initialForm: NodeInitialForm,
    intermediateForm: NodeIntermediateForm
};

const FlowBuilder = ({ initialForm = 0, finalForm = 0, mainField = '' }) => {
    const flowForms = useStore().main.flowForms();
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const range = (start, stop, step) => {
        return Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
        );
    }

    useEffect(() => {
        if (
            (flowForms.length === 2 && initialForm) ||
            (flowForms.length > 2 && initialForm && finalForm)
        ) {
            const total = (flowForms.length - 2) * (200 - 90);
            let coordinates = range(total * -1, total, 200);

            const initialNodes = flowForms.map(o => {
                const formId = String(o.id);
                let obj;

                if (formId === String(initialForm)) {
                    obj = {
                        id: formId,
                        type: 'initialForm',
                        data: { label: o.label, id: formId },
                        position: { x: 0, y: 0 },
                    }
                } else if (formId === String(finalForm)) {
                    obj = {
                        id: formId,
                        type: 'output',
                        data: { label: o.label, id: formId },
                        position: { x: 0, y: flowForms.length === 2 ? 150 : 300 },
                    }
                } else {
                    const x = coordinates.splice(0, 1);
                    obj = {
                        id: formId,
                        type: 'intermediateForm',
                        data: { label: o.label, id: formId },
                        position: { x, y: 150 },
                    }
                }
                return obj
            });

            let edges = [];
            // eslint-disable-next-line
            flowForms.map(o => {
                const formId = String(o.id);

                if (formId !== String(initialForm) && formId !== String(finalForm)) {
                    edges.push({
                        id: `${initialForm}->${formId}`,
                        source: String(initialForm),
                        target: formId,
                        type: 'smoothstep'
                    });
                }
                if (formId !== String(initialForm) && formId !== String(finalForm) && String(finalForm) !== '0') {
                    edges.push({
                        id: `${formId}->${finalForm}`,
                        source: formId,
                        target: String(finalForm),
                        type: 'smoothstep'
                    });
                }
            });

            if (flowForms.length === 2 && initialForm && finalForm) {
                edges.push({
                    id: `${initialForm}->${finalForm}`,
                    source: String(initialForm),
                    target: String(finalForm),
                    type: 'smoothstep'
                });
            }

            setNodes(initialNodes);
            setEdges(edges);
            storeSet.main.flowEdges(edges);
        } else {
            setNodes([]);
            setEdges([]);
        }
    }, [initialForm, finalForm, flowForms, mainField]);

    return (
        !isEmpty(nodes) && !isEmpty(edges)
            ? <div className="flowBuilder__wrapper">
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    fitView
                    nodeTypes={nodeTypes}
                    attributionPosition="top-right"
                >
                    <Background variant="dots" gap={12} size={1}/>
                </ReactFlow>
            </div>
            : null
    );
}

export default FlowBuilder;