import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// tools
import AuthenticationService from '../../service/authentication-service';
import ErrorBoundary from '../ErrorBoundary';

const ProtectedRoute = () => {

    if (!AuthenticationService.wasLoggedIn()) {
        return (<Navigate to={'/login'} replace/>);
    }

    if (AuthenticationService.isExpired()) {
        return (<Navigate to={'/login?redirectReason=expired'} replace/>);
    }

    if (!AuthenticationService.isLoggedIn()) {
        return (<Navigate to={'/login?redirectReason=auth_required'} replace/>);
    }

    /*if (window.location.pathname === '/') {
        return (<Navigate to={'/'} replace/>);
    }*/

    return <ErrorBoundary><Outlet/></ErrorBoundary>;
}

export default ProtectedRoute;