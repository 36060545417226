import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class FileUploadService {

    static uploadFile = (id, body, callback, errCallback, queryParams) => {
        NetworkService.postMultiPart(`${API_BASE_URL}/document/uploadFile/source/${id}`, body, callback, errCallback, queryParams);
    };

    static deleteFile = (body, callback, errCallback, queryParams) => {
        NetworkService.delete(`${API_BASE_URL}/document/deleteFile`, body, callback, errCallback, queryParams);
    };
}
