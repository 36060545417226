import { useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import equal from 'fast-deep-equal';
//import { diff } from 'deep-object-diff';
import { klona } from 'klona';
import { TZDate } from '@date-fns/tz';
import { wrap } from 'object-path-immutable';
import { is, isNil } from 'ramda';

// store
import { storeGet } from '../../store';
import formatDateString from '../../helpers/formatDateString';

const UnsavedChangesDetector = ({ getValuesFn }) => {
    const warnIfUnsavedChanges = (event) => {
        let formData = klona(getValuesFn());
        formData.dates = [];

        if (formData.startDate) {
            let startDate;

            if (is(String, formData.startDate)) {
                startDate = formData.startDate;
            } else {
                startDate = formatDateString(formData.startDate);
            }

            formData = wrap(formData).insert(['dates'], startDate, 0).value();
            delete formData.startDate;
        }
        if (formData.endDate) {
            let endDate;

            if (is(String, formData.endDate)) {
                endDate = formData.endDate;
            } else {
                endDate = formatDateString(formData.endDate);
            }

            formData = wrap(formData).insert(['dates'], endDate, 1).value();
            delete formData.endDate;
        }
        if (!isNil(formData.startTime)) {
            if (!is(String, formData.startTime)) {
                const tzAwareDate = new TZDate(formData.startTime, 'Europe/Berlin');
                formData.startTime = tzAwareDate.toISOString().substring(11, 16);
            }
        }
        if (!isNil(formData.endTime)) {
            if (!is(String, formData.endTime)) {
                const tzAwareDate = new TZDate(formData.endTime, 'Europe/Berlin');
                formData.endTime = tzAwareDate.toISOString().substring(11, 16);
            }
        }
        const initial = storeGet.main.formInitialData();

        const isEqual = equal(initial, formData);
        // debug
        /*console.log('isEqual', isEqual,
            initial, formData,
            diff(initial, formData))*/
        if (!isEqual) {
            event.returnValue = __('You have unsaved changes. If you proceed, they will be lost.', 'gepafin');
        }

        return event.returnValue;
    };

    useEffect(() => {
        window.addEventListener('beforeunload', warnIfUnsavedChanges);

        return () => {
            window.removeEventListener('beforeunload', warnIfUnsavedChanges);
        }
    }, []);
}

export default UnsavedChangesDetector;
