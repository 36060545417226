import { NetworkService } from './network-service';
import { jwtDecode } from 'jwt-decode';

// store
import { storeGet } from '../store';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class AuthenticationService {

    static wasLoggedIn = () => {
        const token = storeGet.main.getToken();
        return token ?? false;
    };

    static isExpired = () => {
        const token = storeGet.main.getToken();

        if (!token) {
            return false;
        }

        let decoded = jwtDecode(token);

        if (!decoded) {
            return false;
        }

        let currentTs = new Date().getTime();

        if (currentTs >= (decoded.exp * 1000)) {
            return true; //FIXME: try refresh
        }

        return false;
    };

    static isLoggedIn = () => {
        const token = storeGet.main.getToken();

        if (!token) {
            return false;
        }

        let decoded = jwtDecode(token);

        if (!decoded) {
            return false;
        }

        let currentTs = new Date().getTime();

        if (currentTs >= decoded.exp * 1000) {
            return false; //FIXME: try refresh
        }

        return true;
    };

    static login = (loginRequest, callback, errCallback) => {
        NetworkService.unauthorizedPost(`${API_BASE_URL}/user/login`, loginRequest, callback, errCallback);
    };

    static registerUser = (registerRequest, callback, errCallback, queryParams) => {
        NetworkService.unauthorizedPost(`${API_BASE_URL}/user`, registerRequest, callback, errCallback, queryParams);
    };

    static forgotPassword = (body, callback, errCallback) => {
        NetworkService.unauthorizedPost(`${API_BASE_URL}/user/reset-password/initiate`, body, callback, errCallback);
    }

    static resetPassword = (body, callback, errCallback) => {
        NetworkService.unauthorizedPost(`${API_BASE_URL}/user/reset-password`, body, callback, errCallback);
    }

    static me = (callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/user/me`, callback, errCallback);
    };

    static validateNewUser = (token, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/user/sso/validate/new-user/${token}`, callback, errCallback);
    };

    static validateExistingUser = (token, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/user/sso/validate/existing-user/${token}`, callback, errCallback);
    };
}
