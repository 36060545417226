/* data table related */
import { __ } from '@wordpress/i18n';

const currentPageReportTemplate = '';

const emptyMessage = __('Nessun dato disponibile', 'gepafin');

const selectOneLabel = __('Seleziona', 'gepafin');

const translationStrings = {
    currentPageReportTemplate,
    emptyMessage,
    selectOneLabel
}

export default translationStrings;