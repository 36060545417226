import React from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';

// components
import AllBandiTable from './components/AllBandiTable';
import { Button } from 'primereact/button';

const Bandi = () => {
    const navigate = useNavigate();

    const onGoToCreateNewBando = () => {
        navigate('/bandi/new');
    }

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Gestione bandi', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <div className="appPageSection__actions">
                    <Button
                        onClick={onGoToCreateNewBando}
                        label={__('Crea nuovo bando')} icon="pi pi-plus" iconPos="right"/>
                </div>

                <AllBandiTable/>
            </div>
        </div>
    )
}

export default Bandi;