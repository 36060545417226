import React, { useState, useEffect} from 'react';
import { __ } from '@wordpress/i18n';
import { is, uniq, isNil, isEmpty } from 'ramda';
import { wrap } from 'object-path-immutable';
import { useNavigate } from 'react-router-dom';

// store
import { storeGet, storeSet, useStore } from '../../../../store';

// tools
import getBandoSeverity from '../../../../helpers/getBandoSeverity';
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getDateFromISOstring from '../../../../helpers/getDateFromISOstring';
import set404FromErrorResponse from '../../../../helpers/set404FromErrorResponse';
import getNumberWithCurrency from '../../../../helpers/getNumberWithCurrency';
import renderHtmlContent from '../../../../helpers/renderHtmlContent';

// api
import BandoService from '../../../../service/bando-service';
import PreferredBandoService from '../../../../service/preferred-bando-service';

// components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Button } from 'primereact/button';

// i18n
import translationStrings from '../../../../translationStringsForComponents';

const AllBandiAccordion = ({ showOnlyPreferred = false }) => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        storeSet.main.setAsyncRequest();
        BandoService.getBandi(getCallback, errGetCallbacks, [
            ['companyId', chosenCompanyId],
            ['onlyPreferredCall', showOnlyPreferred]
        ]);
    }, [chosenCompanyId]);

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setItems(getFormattedBandiData(data.data));
            setStatuses(uniq(data.data.map(o => o.status)))
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallbacks = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormattedBandiData = (data) => {
        return data.map((d) => {
            d.dates = d.dates.map(v => is(String, v) ? new Date(v) : (v ? v : ''));
            return d;
        });
    };

    const nameBodyTemplate = (rowData) => {
        return <span
            className="appPageSection__titleClickable"
            onClick={() => {
                let newExpandedRows;
                if (isNil(expandedRows) || isNil(expandedRows[rowData.id])) {
                    newExpandedRows = isNil(expandedRows)
                        ? wrap({}).set([rowData.id], true).value()
                        : wrap(expandedRows).set([rowData.id], true).value();
                } else {
                    newExpandedRows = wrap(expandedRows).del([rowData.id]).value();
                }
                setExpandedRows(newExpandedRows);
            }}>{rowData.name}</span>
    }

    const amountBodyTemplate = (rowData) => {
        return <span
            className="appPageSection__titleClickable"
            onClick={() => {
                let newExpandedRows;
                if (isNil(expandedRows) || isNil(expandedRows[rowData.id])) {
                    newExpandedRows = isNil(expandedRows)
                        ? wrap({}).set([rowData.id], true).value()
                        : wrap(expandedRows).set([rowData.id], true).value();
                } else {
                    newExpandedRows = wrap(expandedRows).del([rowData.id]).value();
                }
                setExpandedRows(newExpandedRows);
            }}>{getNumberWithCurrency(rowData.amount)}</span>;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel} className="p-column-filter"
                         showClear/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const addToFavourites = (id, preferredId) => {
        const companyId = storeGet.main.chosenCompanyId()
        const data = {
            companyId,
            callId: id
        }
        if (preferredId && preferredId !== 0) {
            PreferredBandoService.deleteFromPreferred(preferredId, (data) => removeFavCallback(data, id), errToggleFavCallback);
        } else {
            PreferredBandoService.addToPreferred(data, addFavCallback, errToggleFavCallback);
        }
    }

    const removeFavCallback = (data, id) => {
        if (data.status === 'SUCCESS') {
            const newItems = items.map((o) => {
                if (o.id === id) {
                    return {
                        ...o,
                        preferredCallId: null
                    }
                } else {
                    return o;
                }
            });
            setItems(newItems)
        }
    }
    const addFavCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const newItems = items.map((o) => {
                if (o.id === data.data.callId) {
                    return {
                        ...o,
                        preferredCallId: data.data.id
                    }
                } else {
                    return o;
                }
            });
            setItems(newItems)
        }
    }

    const errToggleFavCallback = (data) => {
        set404FromErrorResponse(data);
    }

    const goToBandoPage = (id) => {
        navigate(`/bandi/${id}`)
    }

    const actionsBodyTemplate = (rowData) => {
        return <div className="appPageSection__tableActions">
            <button type="button"
                    className="appPageSection__addToFavourites"
                    data-active={!isNil(rowData.preferredCallId)}
                    onClick={() => addToFavourites(rowData.id, rowData.preferredCallId)}>
                <i className="pi pi-heart" style={{ fontSize: '1rem' }}></i>
            </button>
        </div>
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                {renderHtmlContent(data.descriptionShort)}
                <p>{__('Scadenza', 'gepafin')}: {getDateFromISOstring(data.dates[1])}</p>
                {!isEmpty(chosenCompanyId) && chosenCompanyId !== 0 && !data.confidi
                    ? <Button onClick={() => goToBandoPage(data.id)} severity="info">
                    {__('Partecipa', 'gepafin')}
                </Button> : null}
                {!isEmpty(chosenCompanyId) && chosenCompanyId !== 0 && data.confidi
                    ? <Button onClick={() => goToBandoPage(data.id)} severity="info">
                        {__('Mostra', 'gepafin')}
                    </Button> : null}
            </div>
        );
    };

    const allowExpansion = (rowData) => {
        return true;
    };

    return(
        <div className="appPageSection__table">
            <DataTable value={items}
                       paginator
                       rows={10}
                       loading={isAsyncRequest}
                       dataKey="id"
                       filters={filters}
                       emptyMessage={translationStrings.emptyMessage}
                       expandedRows={expandedRows}
                       onRowToggle={(e) => setExpandedRows(e.data)}
                       rowExpansionTemplate={rowExpansionTemplate}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="name" header={__('Bando', 'gepafin')}
                        body={nameBodyTemplate}
                        style={{ minWidth: '12rem' }}/>
                <Column header={__('Importo totale', 'gepafin')} filterField="amount"
                        style={{ minWidth: '10rem' }} body={amountBodyTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')} filterMenuStyle={{ width: '14rem' }}
                        style={{ width: '8rem' }} body={statusBodyTemplate} filter sortable
                        filterElement={statusFilterTemplate}/>
                {!isEmpty(chosenCompanyId) && chosenCompanyId !== 0
                    ? <Column header={__('Azioni', 'gepafin')}
                        style={{ width: '8rem' }}
                        body={actionsBodyTemplate}/> : null}
            </DataTable>
        </div>
    )
}

export default AllBandiAccordion;