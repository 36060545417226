export const mimeTypes = [
    { name: 'PDF', code: '.pdf,application/pdf' },
    { name: 'p7m', code: '.p7m,application/pkcs7-mime,application/x-pkcs7-mime' },
    { name: 'ZIP', code: 'application/zip' },
    { name: 'Immagine', code: 'image/*' },
    {
        name: 'Word',
        code: '.doc,.docx,.odt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text'
    },
    {
        name: 'Excel',
        code: '.xls,.xlsx,.ods,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet'
    }
];

export const defaultMaxFileSize = 314572800; // 314572800 = 300 Mb

const dynamicDataForTextinput = [
    { label: 'ragione sociale', value: 'company.companyName' },
    { label: 'partita IVA', value: 'company.vatNumber' },
    { label: 'codice fiscale azienda', value: 'company.codiceFiscale' },
    { label: 'indirizzo', value: 'company.address' },
    { label: 'numero di telefono azienda', value: 'company.phoneNumber' },
    { label: 'città', value: 'company.city' },
    { label: 'provincia', value: 'company.province' },
    { label: 'CAP', value: 'company.cap' },
    { label: 'paese', value: 'company.country' },
    { label: 'PEC', value: 'company.pec' },
    { label: 'email azienda', value: 'company.email' },
    { label: 'nome del referente', value: 'company.contactName' },
    { label: 'email del referente', value: 'company.contactEmail' },
    { label: 'email utente', value: 'user.email' },
    { label: 'nome utente', value: 'user.firstName' },
    { label: 'cognome utente', value: 'user.lastName' },
    { label: 'numero di telefono utente', value: 'user.phoneNumber' },
    { label: 'codice fiscale utente', value: 'user.codiceFiscale' },
    { label: 'nome del rappresentante', value: 'custom.legalRepresentant' },
    { label: 'nome e cognome utente', value: 'custom.userFullName' },
]

export const dynamicDataOptions = {
    datepicker: [
        { label: 'user dateOfBirth', value: 'user.dateOfBirth' }
    ],
    textinput: dynamicDataForTextinput,
    textarea: dynamicDataForTextinput
}

export const protocolType = [
    {
        'id': 1,
        'name': 'PROTOCOLLO IN ENTRATA'
    },
    {
        'id': 2,
        'name': 'PROTOCOLLO IN USCITA'
    },
    {
        'id': 3,
        'name': 'DOCUMENTO INTERNO'
    },
    {
        'id': 1003,
        'name': 'PROTOCOLLO INTERNO '
    }
];

export const classificationType = [
    {
        'idClassificazione': 101,
        'name': 'BILANCIO',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 102,
        'name': 'DICHHIARAZIONE DEI REDDITI',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 103,
        'name': 'SITUAZIONE CONTABILE',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 104,
        'name': 'PROSPETTO CONTO ECONOMICO',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 105,
        'name': 'CENTRALE DEI RISCHI',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 106,
        'name': 'RELAZIONE AZIENDALE ILLUSTRATIVA (MOD R1C, R1I, R1R, R1R A SECONDO DEI ',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 107,
        'name': 'DOCUMENTO IDENTITA\'',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 108,
        'name': 'MODELLO SP1',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 109,
        'name': 'PRIVACY',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 110,
        'name': 'DOCUMENTAZIONE CHE ATTESTA POSSIBILITA\' DI RILASCIARE GAA FAVORE',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 111,
        'name': 'MODELLO AR1 D.LG 231/2007',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 112,
        'name': 'DOCUMENTO IDENTITA\' FIRMATORIO DICHHIARAZIONE SOSTITUTIVA',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 113,
        'name': 'PRIVACY FIRMATARIO DICHHIARAZIONE SOSTITUTIVA',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 114,
        'name': 'NULLAOSTA ANTIMAFIA',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 201,
        'name': 'LETTERA ESITO DELIBERA',
        'idTipoprotocollo': 2
    },
    {
        'idClassificazione': 202,
        'name': 'LETTERA DI GARANZIA',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 203,
        'name': 'GENERICO',
        'idTipoprotocollo': 3
    }
];