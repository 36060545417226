import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class PreferredBandoService {

    static addToPreferred = (body, callback, errCallback) => {
        NetworkService.post(`${API_BASE_URL}/beneficiaryPreferredCall`, body, callback, errCallback);
    };

    static deleteFromPreferred = (id, callback, errCallback) => {
        NetworkService.delete(`${API_BASE_URL}/beneficiaryPreferredCall/${id}`, {}, callback, errCallback);
    };

    static getPreferredCalls = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/beneficiaryPreferredCall/user`, callback, errCallback, queryParams);
    };
}
