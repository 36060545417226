import React, { useState, useEffect, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { wrap } from 'object-path-immutable';

// store
import { storeSet, useStore } from '../../store';

// api
import AmendmentsService from '../../service/amendments-service';

// tools
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';

// components
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Editor } from 'primereact/editor';
import { InputNumber } from 'primereact/inputnumber';
import BlockingOverlay from '../../components/BlockingOverlay';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import ApplicationEvaluationService from '../../service/application-evaluation-service';

const SoccorsoAddPreInstructor = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [evaluationId, setEvaluationId] = useState(0);
    const [formData, setFormData] = useState({});
    const toast = useRef(null);

    const goToEvaluationPage = () => {
        navigate(`/domande/${id}`);
    }

    useEffect(() => {
        const parsed = parseInt(id)
        const entityId = !isNaN(parsed) ? parsed : 0;

        storeSet.main.setAsyncRequest();
        ApplicationEvaluationService.getEvaluationByApplId(getCallbackEvaluation, errGetCallback, [
            ['applicationId', entityId]
        ]);
    }, [id]);

    const getCallbackEvaluation = (data) => {
        if (data.status === 'SUCCESS') {
            setEvaluationId(data.data.assignedApplicationId);
            AmendmentsService.getSoccorsoByApplEvalId(data.data.assignedApplicationId, getCallback, errGetCallback)
        }
    }

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setData(data.data);
            setFormData(getFormattedFormData(data.data));
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormattedFormData = (data) => {
        let newData = {};
        newData.formFields = data.formFields;
        newData.responseDays = 10;
        newData.note = '';
        newData.isSendNotification = true;
        newData.isSendEmail = true;
        return newData;
    };

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-header" value="2"></button>
                <button className="ql-header" value="3"></button>
                <button className="ql-blockquote"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
        );
    };

    const header = renderHeader();

    const updateEvaluationValue = (value, path, maxValue) => {
        let finalValue = value;

        if (maxValue) {
            finalValue = value > maxValue ? maxValue : value;
        }

        const newData = wrap(formData).set(path.split('.'), finalValue).value();

        setFormData(newData);
    }

    const doCreate = () => {
        storeSet.main.setAsyncRequest();

        AmendmentsService.createSoccorso(formData, createCallback, errCreateCallback, [
            ['applicationEvaluationId', evaluationId]
        ]);
    }

    const createCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
            setTimeout(() => {
                navigate(`/domande/${id}/soccorso/${data.data.id}`);
            }, 1000)
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errCreateCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Richiesta Integrazione Documentale', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>
            <Toast ref={toast}/>
            <BlockingOverlay shouldDisplay={isAsyncRequest}/>

            <div className="appPageSection__row">
                <Button
                    type="button"
                    outlined
                    onClick={goToEvaluationPage}
                    label={__('Indietro', 'gepafin')}
                    icon="pi pi-arrow-left" iconPos="left"/>
            </div>

            <div className="appPage__spacer"></div>

            {!isAsyncRequest && !isEmpty(data)
                ? <div className="appPage__content">
                    <div className="appPageSection__withBorder column">
                        <p className="appPageSection__pMeta">
                            <span>{__('ID domanda', 'gepafin')}</span>
                            <span>{data.applicationId}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Bando', 'gepafin')}</span>
                            <span>{data.callName}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Beneficiario', 'gepafin')}</span>
                            <span>{data.beneficiaryName}</span>
                        </p>
                    </div>

                    <div className="appPageSection">
                        <div className="appPageSection columns">
                            <div>
                                <h3>{__('Note', 'gepafin')}</h3>
                                <div style={{marginBottom: '30px'}}>
                                    <Editor
                                        value={formData.note}
                                        placeholder={__('Digita qui il messagio', 'gepafin')}
                                        headerTemplate={header}
                                        onTextChange={(e) => updateEvaluationValue(
                                            e.htmlValue,
                                            'note'
                                        )}
                                        style={{ height: 80 * 3, width: '100%' }}
                                    />
                                </div>

                                <h3>{__('Tempo per la Risposta (giorni)', 'gepafin')}</h3>
                                <div style={{marginBottom: '30px'}}>
                                    <InputNumber
                                        keyfilter="int"
                                        value={formData.responseDays}
                                        showButtons
                                        onChange={(e) => updateEvaluationValue(
                                            e.value,
                                            'responseDays',
                                            9999
                                        )}/>
                                </div>

                                <h3>{__('Notifica', 'gepafin')}</h3>
                                <div className="appPageSection__withBorder grey">
                                    <div className="appForm__field row">
                                        <InputSwitch
                                            inputId="notify_email"
                                            checked={formData.isSendEmail}
                                            onChange={(e) => updateEvaluationValue(
                                                e.value,
                                                'isSendEmail'
                                            )}/>
                                        <label htmlFor="notify_email">{__('Notifiche Email', 'gepafin')}</label>
                                    </div>
                                    <div className="appForm__field row">
                                        <InputSwitch
                                            inputId="notify_push"
                                            checked={formData.isSendNotification}
                                            onChange={(e) => updateEvaluationValue(
                                                e.value,
                                                'isSendNotification'
                                            )}/>
                                        <label htmlFor="notify_push">{__('Notifiche Push', 'gepafin')}</label>
                                    </div>
                                </div>
                            </div>
                            {formData.formFields
                                ? <div>
                                    <h3>{__('Documenti da Integrare', 'gepafin')}</h3>
                                    <div className="appPageSection__withBorder grey">
                                        <div className="appPageSection__checklist">
                                            {formData.formFields.map((o, i) => <div key={o.fieldId}>
                                                <Checkbox
                                                    inputId={`checklist_${o.fieldId}`}
                                                    onChange={(e) => updateEvaluationValue(
                                                        e.checked,
                                                        `formFields.${i}.selected`
                                                    )}
                                                    checked={o.selected}></Checkbox>
                                                <label htmlFor={`checklist_${o.fieldId}`}>{o.label}</label>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>

                    <div className="appPageSection__message warning">
                        <i className="pi pi-exclamation-triangle"></i>
                        <span className="summary">{__('Attenzione', 'gepafin')}</span>
                        <span>{__("L'invio della richiesta di integrazione sospenderà il termine di valutazione della domanda.", 'gepafin')}</span>
                    </div>

                    <div className="appPageSection__hr">
                        <span>{__('Azioni', 'gepafin')}</span>
                    </div>

                    <div className="appPageSection">
                        <div className="appPageSection__actions">
                            <Button
                                type="button"
                                outlined
                                label={__('Anulla', 'gepafin')}
                                icon="pi pi-times" iconPos="right"/>
                            <Button
                                type="button"
                                onClick={doCreate}
                                label={__('Invia richiesta', 'gepafin')}
                                icon="pi pi-check" iconPos="right"/>
                        </div>
                    </div>

                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem"></Skeleton>
                </>}
        </div>
    )

}

export default SoccorsoAddPreInstructor;