import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

const LOCAL_DEVELOPMENT = process.env.REACT_APP_LOCAL_DEVELOPMENT;

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            builderError: false
        };
    }

    static getDerivedStateFromError(error) {
        try {
            if (LOCAL_DEVELOPMENT !== '1') {
                Sentry.init({
                    dsn: "https://e7b2134f7d816f663bb83e51b106a694@o4508381921738752.ingest.de.sentry.io/4508381935501392",
                    environment: process.env.NODE_ENV || "development"
                });

                Sentry.captureException(error);
            }
        } catch (err) {
            console.log('err')
            console.log(err);
        }
        return { builderError: true };
    }

    render() {
        if (this.state.builderError) {
            return <div>Error</div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;