import React from 'react';

const logofileName = process.env.REACT_APP_LOGO_FILENAME;
const appTitle =  process.env.REACT_APP_TAB_TITLE;

const LogoIcon = () => {
    const { origin } = window.location;
    return <img className="appLogo" src={`${origin}/loaded-files/${logofileName}`} alt={appTitle} />
}

export default LogoIcon;