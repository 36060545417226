import React from 'react';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import { isNil, isEmpty, is } from 'ramda';

// components
import { InputSwitch } from 'primereact/inputswitch';

const Switch = ({
                    fieldName,
                    label,
                    control,
                    errors,
                    defaultValue,
                    config = {},
                    infoText = null,
                    onLabel = '',
                    offLabel = '',
                    disabled = false
                }) => {
    const properValue = !isNil(defaultValue) && !isEmpty(defaultValue) && defaultValue !== false;

    const input = <Controller
        name={fieldName}
        control={control}
        defaultValue={properValue}
        rules={config}
        render={({ field, fieldState }) => (
            <InputSwitch
                inputId={fieldName}
                checked={is(String, field.value) ? 'true' === field.value : field.value}
                disabled={disabled}
                onChange={(e) => field.onChange(e.value)}
                className={classNames({ 'p-invalid': fieldState.invalid })}/>
        )}/>
    return (
        <>
            <div className="appForm__row">
                <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] }, 'mr-8')}>
                    {label}{config.required || config.isRequired ? <span className="appForm__field--required">*</span> : null}
                </label>
                <div className="appForm__row">
                    {offLabel ? <span>{offLabel}</span> : null}
                    {input}
                    {onLabel ? <span>{onLabel}</span> : null}
                </div>
            </div>
            {infoText ? <small>{infoText}</small> : null}
        </>)
}

export default Switch;